import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  ActivityIndicator,
  TouchableOpacity,
  Dimensions,
  Image,
  Pressable,
  TextInput,
} from "react-native";

import { useMediaQuery } from "react-responsive";

import FileSaver from "file-saver";
import XLSX from "xlsx";

import {
  ScreenHeader,
  Dropdown2,
  InputWithName2,
  GradientButton3,
} from "../../components";
import styles from "./style";
import { EDColors } from "../../constants/Colors";
import { Picker } from "@react-native-community/picker";

import {
  getLevel,
  getSectionData,
  getQuesDataByLevelAndSection,
  addNewItem,
  deleteItem,
  updateItem,
  getTotalSectionData,
  addNewItemToSection,
  updateBulkQues,
  getQuesDataByLevelAndCustomID,
} from "../../actions/OfflineAction";
import Answer from "../answer";
import {
  FontAwesome,
  FontAwesome5,
  Ionicons,
  MaterialIcons,
} from "@expo/vector-icons";
import { ScrollView } from "react-native-gesture-handler";
import AddModal from "../answer/addModal";
import AlertModal from "../answer/AlertModal";
import { Assets } from "../../constants/Images";
import { Units } from "../../constants/Unit";
import SideBar from "../../components/sidebar";
import { createStackNavigator } from "@react-navigation/stack";
import Header from "../../components/Header";
import HeaderComp from "./headerComp";
import QuesInput from "./quesInput";
import QuesDropDown from "./quesDropDown";
import QuesData from "./quesData";
import { toast } from "react-toastify";
import Modal from "../../components/Modal";

const dashStack = new createStackNavigator();

// import {level1QuesData} from '../../mockup/level1';
// import {sectionDataForLevel1} from '../../mockup/section1';

const Study = ({ navigation }) => {
  const [renderData, setRenderData] = useState([]);
  const [sectionData, setSectionData] = useState([]);
  const [curIndex, setCurrentIndex] = useState(0);
  const [curSectionIdx, setCurSectionIdx] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [addMoreIsLoading, setAddMoreIsLoading] = useState(false);
  const [questionLen, setQuestionLen] = useState(0);
  const [quesData, setQuesData] = useState([]);
  const [renderQuesData, setRenderQuesData] = useState([]);
  const [totalSecData, setTotalSecData] = useState([]);
  const [crtCustomIndex, setCrtCustomIndex] = useState(-1);
  const [crtTopicIndex, setCrtTopicIndex] = useState(-1);
  const [modalUpdateAllConfirmOpen, setModalUpdateAllConfirmOpen] =
    useState(false);

  const [window, setHeight] = useState(Dimensions.get("window"));
  const [modalVisible, setModalVisible] = useState(false);
  const [qNo, setQNo] = useState("");
  const [alertModal, setAlertModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [changedItems, setChangedItems] = useState([]);

  useEffect(() => {
    console.log(crtTopicIndex);
  }, [crtTopicIndex]);

  const isMobile = useMediaQuery({
    query: "(max-width: 680px)",
  });

  useEffect(() => {
    Dimensions.addEventListener("change", onChange);
    return () => {
      Dimensions.removeEventListener("change", onChange);
    };
  });
  useEffect(() => {
    getLevelData();
    return () => {
      setQuesData(null);
    };
  }, []);

  const handleUpdateMultiple = async () => {
    try {
      await updateBulkQues(changedItems);
      setChangedItems([]);
      toast.success("Successfully finished updating.");
      setModalUpdateAllConfirmOpen(false)
      setTimeout(() => {
        console.log("TIMEOUT RESET CHANGED ITEM");
        setChangedItems([]);
      }, 2000)
      // if(curIndex === 0) {
      //   getLevelData();
      // } else if(curIndex === 1) {
      //   getSectionDataByLevel(parseInt(curIndex) + 2);
      // } else {
      //   getSectionDataByLevel(parseInt(curIndex) + 2);
      // }
    } catch (e) {
      console.log("UPDATE BULK ERROR: ", e);
      toast.error("Error while updating data please check your connection.");
    }
  };


  const onChange = ({ window, screen }) => {
    setHeight(window);
  };

  const getLevelData = async () => {
    getLevel()
      .then((level) => {
        const filtered = level.filter((prev) => {
          return prev.LevelId !== 2 && prev.LevelId !== 3;
        });
        setData(filtered);

        getTotalSectionData()
          .then((res) => {
            //removed because we removed the old general and amateur from the list
            // const s1 = res.filter((_) => _.LevelId === 2);
            // const s2 = res.filter((_) => _.LevelId === 3);
            const s3 = res.filter((_) => _.LevelId === 4);
            const s4 = res.filter((_) => _.LevelId === 5);
            const s5 = res.filter((_) => _.LevelId === 6);
            setTotalSecData([s3, s4, s5]);
            getSectionDataByLevel(4);
          })
          .catch((err) => { });
      })
      .catch((err) => {
        alert("Please login or check your network status.\n" + err);
        navigation.pop();
      });
  };

  const getSectionDataByLevel = (id) => {
    getSectionData(id)
      .then((section) => {
        setSectionData(section, id);
        getQuestionData(
          id,
          parseInt(curSectionIdx) === -1
            ? ""
            : section[curSectionIdx].SectionId,
          crtCustomIndex !== -1 ? parseInt(crtCustomIndex) : "",
          crtTopicIndex !== -1 ? parseInt(crtTopicIndex) : ""
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setData = (arr) => {
    const newLevelData = [
      {
        LevelId: 4,
        Name: "Technician License (2022 - 2026)",
        ShortDesc: "Beginners start here!",
        Status: true,
        id: "uDBNGCjyqit98hECx24v-new",
      },
      {
        LevelId: 5,
        Name: "General License (2023 - 2027)",
        ShortDesc: "Upgrade to General and expand your privileges.",
        Status: true,
        id: "7yPtaVP9F1QjPJonol4q-new",
      },
      {
        LevelId: 6,
        Name: "Amateur Extra (2024 - 2028)",
        ShortDesc: "The final class.",
        Status: true,
        id: "d0891411db834651a53baa679477e7bd-new",
      },
    ];
    const data = [...arr, ...newLevelData];
    data.shift();
    setRenderData(data);
  };

  const _handleSelectItem = (index) => {
    console.log("first", index);
    setRenderQuesData([]);
    setLoading(true);
    setCurrentIndex(index);
    setQNo("");
    getSectionDataByLevel(parseInt(index) + 4);
  };

  const _handleSelectSectionItem = (index) => {
    setLoading(true);
    setCurSectionIdx(index);
    setQNo("");
    console.log("first");
    if (parseInt(index) === -1) {
      getQuestionData(parseInt(curIndex) + 4, "");
    } else {
      getQuestionData(
        parseInt(curIndex) + 4,
        sectionData[index].SectionId,
        crtCustomIndex !== -1 ? parseInt(crtCustomIndex) : "",
        crtTopicIndex !== -1 ? parseInt(crtTopicIndex) : ""
      );
    }
  };

  const _handleCustomLessonId = (index) => {
    setLoading(true);
    setCrtCustomIndex(index);
    setQNo("");
    if (parseInt(index) === -1) {
      getQuestionData(parseInt(curIndex) + 4, "");
    } else {
      getQuestionData(
        parseInt(curIndex) + 4,
        curSectionIdx === "" ? sectionData[curSectionIdx].SectionId : "",
        index === "" ? "" : parseInt(index),
        crtTopicIndex !== -1 ? parseInt(crtTopicIndex) : ""
      );
    }
  };

  const _handleCustomTopicId = (index) => {
    setLoading(true);
    setCrtTopicIndex(index);
    setQNo("");
    if (parseInt(index) === -1) {
      getQuestionData(parseInt(curIndex) + 4, "");
    } else {
      getQuestionData(
        parseInt(curIndex) + 4,
        curSectionIdx !== -1 ? sectionData[curSectionIdx].SectionId : "",
        crtCustomIndex !== -1 ? parseInt(crtCustomIndex) : "",
        index === "" ? "" : parseInt(index)
      );
    }
  };

  const getQuestionData = (lid, sId, cId, tId) => {
    getQuesDataByLevelAndSection(lid, sId, cId, tId)
      .then((res) => {
        if (res) {
          setQuestionLen(res.length);
          setQuesData(res);
          setRenderQuesData(res);
          setLoading(false);
        }
      })
      .catch((err) => { });
  };

  const handleAddItem = (item) => {
    console.log("ITEM : ", item);
    setLoading(true);
    addNewItem(item).then((res) => {
      if (res) {
        // if (curIndex === 0) {
        //   getLevelData();
        // } else {
        getSectionDataByLevel(parseInt(curIndex) + 4);
        // }
        // getQuestionData(parseInt(curIndex) + 1, parseInt(curSectionIdx) === -1 ? "" : sectionData[curSectionIdx].SectionId);
        setModalVisible(false);
        alert("New item added!");
      }
    });
  };

  const handleUpdate = (id, item) => {
    setLoading(true);
    updateItem(id, item)
      .then((res) => {
        if (res) {
          console.log("index", curIndex);
          if (curIndex === 0) {
            getLevelData();
          } else if (curIndex === 1) {
            getSectionDataByLevel(parseInt(curIndex) + 4);
          } else {
            getSectionDataByLevel(parseInt(curIndex) + 4);
          }
          // getQuestionData(parseInt(curIndex) + 1, parseInt(curSectionIdx) === -1 ? "" : sectionData[curSectionIdx].SectionId);
          alert("Update succeed!");
        }
      })
      .catch((err) => {
        console.log("ERROR: ", err);
      });
  };
  const handleDelete = (id) => {
    setLoading(true);
    deleteItem(id).then((res) => {
      if (res) {
        getLevelData();
        // getQuestionData(parseInt(curIndex) + 1, parseInt(curSectionIdx) === -1 ? "" : sectionData[curSectionIdx].SectionId);
        alert("Delete succeed!");
      }
    });
  };

  const _handleSearch = async (txt) => {
    setQNo(txt.toUpperCase());
    // if (txt.length < 2) return;
    const temp = await quesData.filter(
      (_) =>
        _.QNo.includes(txt.toUpperCase()) || _.QNo.includes(txt.toLowerCase())
    );
    setRenderQuesData(temp);
  };

  const handleExportToCSV = async () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".csv";
    const levelIndex = parseInt(curIndex) + 4;
    const sectionIndex = parseInt(curSectionIdx);
    const fileName =
      sectionIndex === -1
        ? "Question_" + `${levelIndex}`
        : "Question_" + `${levelIndex}_` + `${sectionIndex}`;

    const temp = [];
    await quesData.forEach((item, index) => {
      console.log(item);
      temp.push({
        Level: item.LevelId,
        QNo: item.QNo,
        Section: item.SectionId,
        Question: item.Question,
        AnswerA: item.AnswerA,
        AnswerB: item.AnswerB,
        AnswerC: item.AnswerC,
        AnswerD: item.AnswerD,
        CustomLessonID: item?.CustomLessonID ? item?.CustomLessonID : "",
        CustomTopicID: item?.CustomTopicID ? item?.CustomTopicID : "",
        "Correct Answer": item.Correctanswer,
        Help: item.Help,
        "Is Image Question": item.IsImageQuestion,
        "Image Name": item.imageName,
      });

      if (index === quesData.length - 1) {
        const ws = XLSX.utils.json_to_sheet(temp);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      }
    });
  };

  // const handleAddLevel4Data = async () => {
  // setLoading(true);
  // const tempData = level1QuesData.data;
  // tempData.forEach((_item, index) => {
  //   const addItem = {
  //     ..._item,
  //     LevelId: 4
  //   }
  //   addNewItem(addItem).then((res)=>{
  //     if(res) {
  //       console.log("Added new item ::", index);
  //     }
  //   })
  //   if (tempData.length - 1 === index) {
  //     console.log("========= all data added successfull =========")
  //     setLoading(false);
  //   }
  // })
  // }

  // const handleAddSectionDataForLevel4 = async () => {
  //   setLoading(true);
  //   sectionDataForLevel1.forEach((_item, index) => {
  //     addNewItemToSection(_item).then((res)=>{
  //       if(res) {
  //         console.log("Added new item ::", index);
  //       }
  //     })
  //     if (sectionDataForLevel1.length - 1 === index) {
  //       console.log("========= all data added successfull =========")
  //       setLoading(false);
  //     }
  //   })
  // }

  return (
    <View
      style={[
        styles.container,
        { maxHeight: window.height, flexDirection: "row" },
      ]}
    >
      {/* <GradientButton3 
        text="Add Sectio Data For Level4"
        handlePress={()=>handleAddSectionDataForLevel4()}
      /> */}
      <SideBar navigation={navigation} />
      {/* <dashStack.Navigator> */}
      <>
        <ScrollView contentContainerStyle={styles.scrollView}>
          <Header />
          <HeaderComp
            questionLen={questionLen}
            curIndex={curIndex}
            _handleSelectItem={_handleSelectItem}
            renderData={renderData}
            curSectionIdx={curSectionIdx}
            _handleSelectSectionItem={_handleSelectSectionItem}
            sectionData={sectionData}
            handleExportToCSV={handleExportToCSV}
            _handleSearch={_handleSearch}
            qNo={qNo}
            crtCustomIndex={crtCustomIndex}
            setCrtCustomIndex={setCrtCustomIndex}
            _handleCustomLessonId={_handleCustomLessonId}
            crtTopicIndex={crtTopicIndex}
            _handleCustomTopicId={_handleCustomTopicId}
          />
          {loading || renderData.length === 0 ? (
            <ActivityIndicator size="large" color={EDColors.gradient[2]} />
          ) : (
            <Answer
              updateItem={(_id, _item) => handleUpdate(_id, _item)}
              deleteItem={(_id) => {
                if (modalVisible) return;
                setDeleteId(_id);
                setAlertModal(true);
              }}
              levelData={renderData}
              isMobile={isMobile}
              sHeight={window.height}
              quesData={renderQuesData}
              sectionData={totalSecData}
              setChangedItems={setChangedItems}
              changedItems={changedItems}
            />
          )}
        </ScrollView>
        <TouchableOpacity
          activeOpacity={0.7}
          onPress={() => setModalVisible(true)}
          style={[styles.fabBtn, { top: window.height - 80 }]}
        >
          <FontAwesome name="plus" color={EDColors.white} size={30} />
        </TouchableOpacity>
        <TouchableOpacity
          activeOpacity={0.7}
          onPress={() => {
            if (changedItems.length > 0) {
              setModalUpdateAllConfirmOpen(true);
            }
          }}
          style={[styles.fabLeftBtn, { top: window.height - 80 }]}
        >
          <Text style={styles.fabLeftBtnTxt}>
            Update {changedItems.length} changed items
          </Text>
        </TouchableOpacity>
        <View style={styles.modalCon}>
          <AddModal
            modalVisible={modalVisible}
            onClose={() => setModalVisible(false)}
            width={isMobile ? "90%" : "65%"}
            sWidth={window.width}
            sHeight={window.height}
            levelData={renderData}
            isMobile={isMobile}
            quesData={quesData}
            sectionData={totalSecData}
            addNewItem={(item) => handleAddItem(item)}
          />
          <AlertModal
            width={isMobile ? "80%" : "50%"}
            sWidth={window.width}
            sHeight={window.height}
            msg={"Are you sure?"}
            isMobile={isMobile}
            modalVisible={alertModal}
            deleteItem={() => {
              handleDelete(deleteId);
              setAlertModal(false);
            }}
            onClose={() => setAlertModal(false)}
          />
        </View>
        {/* </dashStack.Navigator> */}
        {modalUpdateAllConfirmOpen ? (
          <Modal
            handleClick={
              changedItems.length !== 0 ? handleUpdateMultiple : () => { }
            }
            setModalOpen={modalUpdateAllConfirmOpen}
            message={`Are you sure you want to update ${changedItems.length} Questions?`}
            handleClose={() => {
              setModalUpdateAllConfirmOpen(false);
            }}
          />
        ) : null}
      </>
    </View>
  );
};

export default Study;
