import * as React from 'react';
import { 
    View,
    Text,
    TextInput,
    StyleSheet,
} from 'react-native';
import { EDColors } from '../constants/Colors';
import { Units } from '../constants/Unit';

const InputWithName= ({label, placeholder, value, onChangeText, isMobile}) => {
    const [error, setError] = React.useState("");
    const oneLine = label.includes("Question Number") || label.includes("Lesson ID") || label.includes("Topic ID");
    const numeric = label.includes("Lesson ID") || label.includes("Topic ID");

    const validateInputs = (text) => {
        let numreg = /^[0-9]+$/;
        if(numeric) {
            if(text.length === 0) {
                setError("");
                onChangeText(null);
            } else if (numreg.test(text)) {
                setError("");
                let num = parseInt(text)
                onChangeText(num);
            } else {
                setError("Must Be a Number");
                let num = parseInt(text)
                if(!Number.isNaN(num)) {
                    onChangeText(num);
                }
            }
        } else {
            onChangeText(text);
        }
    }

    return (
        <>
            <View style={[styles.container, {
                alignItems: isMobile? "flex-start": "center",
                justifyContent: isMobile? "space-between": "flex-start",
                flexDirection: isMobile? 'column': 'row'
            }]} key={label}>
                <View style={{flexDirection: isMobile? "row": "column", alignItems:isMobile? 'flex-start' : 'flex-end'}}>
                    <Text style={isMobile? styles.label1 : styles.label}>{`${label}: `}</Text>
                    {value === "" && isMobile && label !== "Explanation" && <Text style={styles.valTxt}>{`This field is required.`}</Text>}
                </View>
                <View style={oneLine? {flex: 1, width: '100%'} : {flex: 1, width: '100%', height: 60}}>
                    <TextInput 
                        style={[styles.input, {}]}
                        placeholder = {placeholder}
                        placeholderTextColor={EDColors.grey}
                        value = {value}
                        multiline={true}
                        keyboardType = {numeric ? "numeric" : "default"}
                        onChangeText={(text)=> validateInputs(text)}
                    />
                    {value === "" && !isMobile && label !== "Explanation" && label !== "Lesson ID" && label !== "Topic ID" && <Text style={styles.valTxt}>{`This field is required.`}</Text>}
                    <Text style={styles.valTxt}>
                        {error}
                    </Text>
                </View>
            </View>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex:1,
        // height: 40,
        flexDirection:'row',
        alignItems:'center',
        borderRadius: 5,
        marginBottom: 15,
    },
    input:{
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: Units.ftSizes.small,
        textAlignVertical:'center',
        paddingRight: 5,
        paddingVertical: 5,
        flex: 1,
        width: "100%",
        paddingHorizontal: Units.isTablet? 20: 10,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: EDColors.topbar,
    },
    label: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: Units.ftSizes.default,
        minWidth: 140,
    },
    label1: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: Units.ftSizes.default,
    },
    valTxt: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: Units.ftSizes.small,
        color: EDColors.warning
    }
})
export default InputWithName;