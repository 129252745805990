import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { GradientCircularProgress } from 'react-native-circular-gradient-progress'
import { EDColors } from '../../constants/Colors'
import { Units } from '../../constants/Unit'
import { renderLevel } from '../../constants/Config'

const PracticeComp = ({
    avgScore,
    passRate,
    attempt,
    pass,
    fail,
    levelId
}) => {
    return (
        <View style={styles.container}>
            <Text style={styles.title}>{renderLevel[levelId - 1]?.Name}</Text>
            <Text style={styles.levelId}>Level ID: {levelId}</Text>
            <View style={styles.averageWrapper}>
                <View style={styles.scoreWrapper}>
                    <Text style={styles.scoreTitle}>Average Score:</Text> 
                    <GradientCircularProgress
                        size={54}
                        startColor={EDColors.gradient[2]}
                        middleColor={EDColors.gradient[1]}
                        endColor={EDColors.gradient[0]}
                        progress={avgScore}
                        emptyColor={EDColors.lightgrey}
                        strokeWidth={4}
                        children={<View style={{
                        ...StyleSheet.absoluteFillObject,
                        alignItems: 'center',
                        justifyContent: 'center',
                        }}>
                            <Text style={styles.scorePercent}>{avgScore}%</Text>
                        </View>}
                    />
                </View>
                <View style={styles.scoreWrapper}>
                    <Text style={styles.scoreTitle}>Pass Rate:</Text>
                    <GradientCircularProgress
                        size={54}
                        startColor={EDColors.gradient[2]}
                        middleColor={EDColors.gradient[1]}
                        endColor={EDColors.gradient[0]}
                        progress={passRate}
                        emptyColor={EDColors.lightgrey}
                        strokeWidth={4}
                        children={<View style={{
                            ...StyleSheet.absoluteFillObject,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <Text style={styles.scorePercent}>{passRate}%</Text>
                        </View>}
                    />
                </View>
            </View>
            <View style={styles.horizontal}>
                <Text style={styles.txt}>Number of times attempted</Text>
                <Text style={styles.txt}>:{attempt}</Text>
            </View>
            <View style={styles.horizontal}>
                <Text style={styles.txt}>Number of times <Text style={{
                    color: "#15B86B",
                }}>passed</Text></Text>
                <Text style={styles.txtGreen}>:{pass}</Text>
            </View>
            <View style={styles.horizontal}>
                <Text style={styles.txt}>Number of times <Text style={styles.txtRed}>failed</Text></Text>
                <Text style={styles.txtRed}>:{fail}</Text>
            </View>
        </View>
    )
}

export default PracticeComp

const styles = StyleSheet.create({
    container: {
        backgroundColor: "#fff",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.29,
        shadowRadius: 4.65,

        elevation: 7,
        padding: 25,
        // height: 314,
        width: 500,
        borderRadius: 20,
        marginTop: 25
    },
    title: {
        color: "#0243DD",
        fontSize: 24,
        fontFamily: Units.ftFamily.MontserratB
    },
    levelId: {
        color: "#0243DD",
        fontSize: 20,
        fontFamily: Units.ftFamily.MontserratB
    },
    averageWrapper: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 20
    },
    scoreWrapper: {
        flexDirection: "row",
        alignItems: "center"
    },
    scoreTitle: {
        fontFamily: Units.ftFamily.MontserratB,
        fontSize: 16,
        marginRight: 15
    },
    scorePercent: {
        fontFamily: Units.ftFamily.KarlaR,
        color: EDColors.topbar,
        textAlign: 'center',
    },
    horizontal: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 20
    },
    txt: {
        color: "#010A41",
        fontFamily: Units.ftFamily.MontserratB,
        fontSize: 20
    },
    txtRed: {
        fontFamily: Units.ftFamily.MontserratB,
        color: "#BF1D27",
        fontSize: 20
    },
    txtGreen: {
        color: "#15B86B",
        fontFamily: Units.ftFamily.MontserratB,
        fontSize: 20
    }
})