
export const storageKey = {
    LEVEL_DATA: 'levelData',
    SECTION_DATA: 'sectionData',
    QUESTION_DATA: 'questionData',
    RESULT_LEVEL_USER: "resultLevelUser",
    RESULT_QUESTION_USER: "resultQuestionUser",
    APPSFLYER_USER_EVENT : "appsFlyerUserEvent",
    PRACTICE_RESULT_FOR_USER: "practiceForRFU",
    USER_CLICKED_PREMIUM: "userClickedOnPremium",
    WEB_HOOK_DATA: "webHookData",
    OFFLINE_STATUS: "offlineStatus",
    OFFLINE_FILES: "offlinefiles",
}
