import React from 'react';
import { StyleSheet, View, Text } from 'react-native';

import Modal from 'react-native-modal';
import { GradientButton3 } from '../../components';
import { EDColors } from '../../constants/Colors';
import { Units } from '../../constants/Unit';


const AlertModal = ({ msg, onClose, deleteItem, modalVisible, sWidth, sHeight, width }) => {
    return (
        <Modal
            testID={'modal'}
            isVisible={modalVisible}
            onBackdropPress={onClose}
            onSwipeComplete = {onClose}
            deviceWidth={sWidth}
            deviceHeight={sHeight}
            swipeDirection={['down']}
            hideModalContentWhileAnimating={true}
            style={[styles.view, {width: width, minWidth: 300, maxWidth: 500}]}
        >
            <View style = {[styles.container,  ]}>
                <Text style={styles.closeTxt}>{msg}</Text>
                <View style={styles.actionCon}>
                    <View style={{width: "50%"}} key="1">
                        <GradientButton3
                            text={"confirm"}
                            handlePress={deleteItem}
                        />
                    </View>
                    <View style={{width: "50%"}} key="2">
                        <GradientButton3
                            text={"cancel"}
                            handlePress={onClose}
                        />
                    </View>
                </View>
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({
    view: {
        flex:1,
        justifyContent: 'center',
        margin: 0,
        alignSelf: "center",
    },
    container: {
        borderRadius: 10,
        backgroundColor: EDColors.white,
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: 10,
        paddingBottom: 20,
    },
    actionCon: {
        width: '100%',
        flexDirection: "row",
        justifyContent: 'space-between'
    },
    closeTxt: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: Units.ftSizes.big,
        marginVertical: 30
    },
});

export default AlertModal;