import React, { useEffect, useRef, useState } from 'react';
import {
  View,
  Text,
  ActivityIndicator,
  TouchableOpacity,
  Dimensions,
  TextInput,
  Pressable,
  StyleSheet
} from 'react-native';

import { useMediaQuery } from 'react-responsive';

import { ScreenHeader, Dropdown2, InputWithName2, GradientButton3 } from '../../components';
import styles from './style';
import { EDColors } from '../../constants/Colors';

import Answer from '../answer';
import { AntDesign, Feather, FontAwesome, Ionicons } from '@expo/vector-icons';
import { ScrollView } from 'react-native-gesture-handler';
import AddModal from '../answer/addModal';
import AlertModal from '../answer/AlertModal';
import { createStackNavigator } from '@react-navigation/stack';
import { Units } from '../../constants/Unit';
import SideBar from '../../components/sidebar';
import { GradientCircularProgress } from 'react-native-circular-gradient-progress';
import ResultContainer from './resultContainer';
import MainTab from './mainTab'
import Header from '../../components/Header';
import ResultTab from './resultTab';
import FieldComponent from './fieldComponent';
import EnrollmentComponent from './enrollmentComponent';
import { enrollmentHandler, getUserEnrollmentInfo, getUserExamResultInfo, updateEnrollment } from '../../actions/OfflineAction';
import firebase, { firestore } from 'firebase';
import { tableNames } from '../../constants/Config';
import LottieView from 'lottie-react-native';
import { toast } from 'react-toastify';
import axios from 'axios';
import Modal from '../../components/Modal';
import ModalWithInput from '../../components/ModalWithInput';

const loadingAnimation = require('../../assets/lottieAnimations/98770-assistagro-loading-bars.json');

// import {level1QuesData} from '../../mockup/level1';
// import {sectionDataForLevel1} from '../../mockup/section1';

const StudentInfo = ({ navigation }) => {
  const [userData, setUserData] = useState(null)
  const animation = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTabs, setIsLoadingTabs] = useState(true);
  const [userSearchTxt, setUserSearchTxt] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [currentProductId, setCurrentProductId] = useState(null)
  const [userEmail, setUserEmail] = useState("")
  const [selectedMainTab, setSelectedMainTab] = useState("User Info");
  const [resultTab, setResultTab] = useState("Technician");
  const [userEnrollmentData, setUserEnrollmentData] = useState(null);
  const [userExamData, setUserExamData] = useState(null);
  const [isLoadingEnrollment, setIsLoadingEnrollment] = useState(true);
  const [modalOpen, setModalOpen] = useState(false)
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false)
  const [modalEnrollOpen, setModalEnrollOpen] = useState(false)
  const [productId, setProductId] = useState("")
  const [enrolled, setSetEnrolled] = useState("")

  const [window, setHeight] = useState(Dimensions.get('window'));
  const getUserDetails = firebase.functions().httpsCallable('getUserDetails');
  const deleteUserDetails = firebase.functions().httpsCallable('deleteUser');

  const isMobile = useMediaQuery({
    query: "(max-width: 680px)",
  });

  const fetchUserData = async () => {
    setSelectedMainTab("User Info")
    setResultTab("Technician")
    setUserEnrollmentData(null)
    setIsLoading(true);
    setIsLoadingTabs(true);
    setUserExamData(null);
    setIsLoadingEnrollment(true);
    try {
      const res = await getUserDetails({ email: userSearchTxt })
      console.log("USER DATA RESPONSE: ", res.data)
      setUserData(res.data)
      setUserFirstName(res.data?.displayName ? res.data?.displayName.split(' ')[0] : "")
      setUserLastName(res.data?.displayName ? res.data?.displayName.split(' ')[1] : "")
      setUserEmail(res.data?.email)
    } catch (e) {
      console.log("ERROR WHILE FETCHING USER DATA: ", e)
      toast.warning('User email not found or Something went wrong');
    }
    setIsLoading(false)
  }

  const handleDeleteUser = async () => {
    try {
      const res = await deleteUserDetails({ uid: userData?.uid })
      if (res.data === true) {
        setUserData(null)
        toast.success('User deleted successfully');
      } else {
        toast.warning('Something went wrong');
      }
      setModalDeleteOpen(false)
    } catch (e) {
      console.log("DELETE USER ERROR: ", e)
      toast.warning('Something went wrong');
    }
  }

  const getUserEnrollmentData = async (userId) => {
    setUserEnrollmentData(null);
    setIsLoadingEnrollment(true)
    try {
      const resForEnrollment = await getUserEnrollmentInfo(userId);
      if (resForEnrollment !== null) {
        setUserEnrollmentData(resForEnrollment)
      }
    } catch (e) {
      console.log("ERROR GETTING ENROLLMENT DATA", e)
    }
    setIsLoadingEnrollment(false)
  }

  const getDataForExamResult = async (userId) => {
    setUserExamData(null);
    let resExamResult
    try {
      if (resultTab === "Technician") {
        resExamResult = await getUserExamResultInfo(userId, 4);
      }
      if(resultTab === "General") {
        resExamResult = await getUserExamResultInfo(userId, 5);
      }
      if(resultTab === "Amateur Extra") {
        resExamResult = await getUserExamResultInfo(userId, 6);
      }
      if (resExamResult !== null) {
        setUserExamData(resExamResult)
      }
    } catch (e) {
      console.log("EXAM RESULT ERROR:  ", e)
    }
    setIsLoadingTabs(false)
  }

  const enrollUser = async (productId) => {
    let currentData = {
      Technician: userEnrollmentData?.Technician ? true : false,
      General: userEnrollmentData?.General ? true : false,
      AmateurExtra: userEnrollmentData?.AmateurExtra ? true : false,
    }
    let updateData = {
      Technician: currentData?.Technician,
      General: currentData?.General,
      AmateurExtra: currentData?.AmateurExtra,
    }
    if (productId === 'Technician') {
      updateData.Technician = true
    } else if (productId === 'General') {
      updateData.General = true
    } else if (productId === 'Amateur') {
      updateData.AmateurExtra = true
    }
    enrollmentHandler(userData?.uid, updateData).then(async () => {
      console.log("STARTED")
      if (!userFirstName || !userLastName || !userEmail) {
        setModalOpen(true)
        setCurrentProductId(productId)
      } else {
        await updateEnrollment({
          "first_name": userFirstName,
          "last_name": userLastName,
          "email": userEmail,
          "product_name": productId
        })
      }
      console.log("SUCCUSS");
      getUserEnrollmentData(userData?.uid);
      toast.success('User Successfully Enrolled');
    }).catch(() => {
      console.log("USER ENROLLING ERROR")
      toast('Unable to Enroll user');
    })
    setIsLoadingEnrollment(false)
  }

  const removeEnrollment = async (productId) => {
    let currentData = {
      Technician: userEnrollmentData?.Technician ? true : false,
      General: userEnrollmentData?.General ? true : false,
      AmateurExtra: userEnrollmentData?.AmateurExtra ? true : false,
    }
    let updateData = {
      Technician: currentData?.Technician,
      General: currentData?.General,
      AmateurExtra: currentData?.AmateurExtra,
    }
    if (productId === 'Technician') {
      updateData.Technician = false;
    } else if (productId === 'General') {
      updateData.General = false;
    } else if (productId === 'Amateur') {
      updateData.AmateurExtra = false;
    }
    enrollmentHandler(userData?.uid, updateData).then(async () => {
      console.log("STARTED")
      // const splitedUserName = userData?.displayName ? userData?.displayName.split(' ') : null;
      console.log("SUCCUSS");
      getUserEnrollmentData(userData?.uid);
      toast.success('Successfully Removed Enrolled');
    }).catch(() => {
      console.log("USER ENROLLING ERROR")
      toast('Unable Remove Enrollment');
    })
    setIsLoadingEnrollment(false)
  }

  useEffect(() => {
    if (selectedMainTab === "User Info") {
      // setIsLoading(false);
      setIsLoadingTabs(true);
    } else if (selectedMainTab === "Enrollments") {
      getUserEnrollmentData(userData?.uid);
      setIsLoadingTabs(true);
    } else if (selectedMainTab === "Exam Readiness") {
      getDataForExamResult(userData?.uid)
    }
  }, [resultTab, selectedMainTab])

  useEffect(() => {
    Dimensions.addEventListener('change', onChange);
    return () => {
      Dimensions.removeEventListener('change', onChange);
    }
  }, [])

  const onChange = ({ window, screen }) => {
    setHeight(window);
  }

  return (
    <View style={[styles.container, { maxHeight: window.height, flexDirection: "row" }]}>
      {/* <GradientButton3 
        text="Add Sectio Data For Level4"
        handlePress={()=>handleAddSectionDataForLevel4()}
      /> */}
      <SideBar navigation={navigation} />
      {/* <dashStack.Navigator> */}
      <>
        <ScrollView contentContainerStyle={styles.scrollView}>
          <Header />
          <Text style={styles.title}>User Email</Text>
          <View style={styles.searchWrapper}>
            <TextInput
              value={userSearchTxt}
              onChangeText={(text) => setUserSearchTxt(text)}
              style={styles.input}
            />
            <GradientButton3
              text="Search User"
              handlePress={() => fetchUserData()}
              width={180}
            />
          </View>
          {isLoading ? (
            <View style={styles.loadingWrapper}>
              <ActivityIndicator size="large" color={EDColors.gradient[1]} />
            </View>
          ) : userData ? (
            <View style={styles.bg}>
              <View style={styles.tableContainer}>
                <MainTab setSelectedMainTab={setSelectedMainTab} selectedMainTab={selectedMainTab} onDeletePressed={() => {
                  setModalDeleteOpen(true)
                }} />
                <View style={{ backgroundColor: "#ccc", height: 1, width: "95%", marginHorizontal: "auto" }} />
                {selectedMainTab === "User Info" ? (
                  <View style={[styles.selectedTabContainer, { flexDirection: "row", alignItems: "center", justifyContent: "space-around" }]}>
                    <FieldComponent title="Email: " data={userData.email} />
                    <View style={{ width: 1, height: 25, backgroundColor: "#ccc" }} />
                    <FieldComponent title="First Name: " data={userData?.displayName ? userData?.displayName.split(" ")[0] : "Not Available"} />
                    <View style={{ width: 1, height: 25, backgroundColor: "#ccc" }} />
                    <FieldComponent title="Last Name: " data={userData?.displayName ? userData?.displayName.split(" ")[1] : "Not Available"} />
                  </View>
                ) : selectedMainTab === "Enrollments" ? (
                  <View style={styles.selectedTabContainer}>
                    {!isLoadingEnrollment ? (
                      <>
                        <EnrollmentComponent title="Technician" enrolled={userEnrollmentData?.Technician ? true : false} enrollUser={enrollUser} productId="Technician" setSetEnrolled={setSetEnrolled} setProductId={setProductId} removeEnrollment={removeEnrollment} setIsLoadingEnrollment={setIsLoadingEnrollment} userData={userData} setModalEnrollOpen={setModalEnrollOpen} />
                        <EnrollmentComponent title="General" enrolled={userEnrollmentData?.General ? true : false} enrollUser={enrollUser} productId="General" setSetEnrolled={setSetEnrolled} setProductId={setProductId} removeEnrollment={removeEnrollment} setIsLoadingEnrollment={setIsLoadingEnrollment} userData={userData} setModalEnrollOpen={setModalEnrollOpen} />
                        <EnrollmentComponent title="Amateur Extra" enrolled={userEnrollmentData?.AmateurExtra ? true : false} enrollUser={enrollUser} productId="Amateur" setSetEnrolled={setSetEnrolled} setProductId={setProductId} removeEnrollment={removeEnrollment} setIsLoadingEnrollment={setIsLoadingEnrollment} userData={userData} setModalEnrollOpen={setModalEnrollOpen} />
                      </>
                    ) : isLoadingEnrollment ? (
                      <ActivityIndicator size="large" color={EDColors.gradient[1]} />
                    ) : (
                      <Text>Somthing went wrong</Text>
                    )}
                  </View>
                ) : selectedMainTab === "Exam Readiness" ? (
                  <View style={styles.selectedTabContainer}>
                    <ResultTab setResultTab={setResultTab} setIsLoadingTabs={setIsLoadingTabs} resultTab={resultTab} />
                    <ResultContainer userExamData={userExamData} isLoadingTabs={isLoadingTabs} />
                  </View>
                ) : (null)}
              </View>
            </View>
          ) : null}
          {modalOpen ? (
            <ModalWithInput
              setModalOpen={setModalOpen}
              setCurrentProductId={setCurrentProductId}
              userFirstName={userFirstName}
              setUserFirstName={setUserFirstName}
              userLastName={userLastName}
              setUserLastName={setUserLastName}
              userEmail={userEmail}
              setUserEmail={setUserEmail}
              currentProductId={currentProductId}
              userData={userData}
            />
          ) : (null)}
          {modalDeleteOpen ? (
            <Modal
              handleClick={() => handleDeleteUser()}
              setModalOpen={setModalDeleteOpen}
              message="Are you sure you want to delete user?"
              handleClose={() => {
                setModalDeleteOpen(false)
              }}
            />
          ) : (null)}
          {modalEnrollOpen ? (
            <Modal
              handleClick={async () => {
                setIsLoadingEnrollment(true)
                if (!enrolled) {
                  await enrollUser(productId);
                } else if (enrolled) {
                  await removeEnrollment(productId);
                }
                setSetEnrolled("")
                setProductId("")
                setModalEnrollOpen(false)
              }}
              setModalOpen={setModalEnrollOpen}
              handleClose={() => {
                setSetEnrolled("")
                setProductId("")
                setModalEnrollOpen(false)
              }}
              message="Are you sure you want to change enrollment data?"
            />
          ) : (null)}
        </ScrollView>
      </>
    </View>
  )
};

export default StudentInfo;
