import { Dimensions, Platform } from 'react-native';

const { width, height } = Dimensions.get("screen");
export const em = height > width ? width/720 : height/720;

let deviceType = "web";

const ftsForWeb = {
    small: 12,
    default: 14,
    middefault: 16,
    answer: 18,
    medium: 16,
    ques: 18,
    big: 20,
    title: 26,
    extra: 30,
};
const ftsForPhone = {
    small: 12,
    default: 14,
    middefault: 15,
    answer: 16,
    medium: 16,
    ques: 16,
    big: 18,
    title: 18,
    extra: 25,
};

export const Units = {
    mt: 0,
    statusH: 0,
    screenSize: {
        W: width,
        H: height,
    },
    ftSizes: ftsForWeb,
    mobftSizes: ftsForPhone,
    fonts: {
        "KarlaB": require("../assets/fonts/Karla-Bold.ttf"),
        "KarlaR":require("../assets/fonts/Karla-Regular.ttf"),
        "MontserratB": require("../assets/fonts/Montserrat-Bold.ttf"),
        "MontserratR": require("../assets/fonts/Montserrat-Regular.ttf"),
    },
    ftFamily: {
        "KarlaB": "KarlaB",
        "KarlaR": "KarlaR",
        "MontserratB": "MontserratB",
        "MontserratR": "MontserratR",
    },
    onedaySec: 24*3600,
    isIOS: Platform.OS === "ios",
    isTablet: false,
    testQuesCnt: 10,
    practiceQuesCnt: {
        level1: 35,
        level2: 35,
        level3: 50,
    },
    ruleForSelecting: {
        level1: {
            section1: 6,
            section2: 3,
            section3: 3,
            section4: 2,
            section5: 4,
            section6: 4,
            section7: 4,
            section8: 4,
            section9: 2,
            section10: 3,
        },
        level2: {
            section1: 5,
            section2: 5,
            section3: 3,
            section4: 5,
            section5: 3,
            section6: 2,
            section7: 3,
            section8: 3,
            section9: 4,
            section10: 2,
        },
        level3: {
            section1: 6,
            section2: 5,
            section3: 3,
            section4: 5,
            section5: 4,
            section6: 6,
            section7: 8,
            section8: 4,
            section9: 8,
            section10: 1,
        },
    },
    passPercent: 74,
    wanrnning: "There is no any level data!\nPlease check network state.",
    alpha: ["A", "B", "C", "D"],
    deviceType,
    version: "1.0.0",
    webhookData: ["Typo on this question", "Image not loading", "My app has crashed", "Other"],
    passedStatus: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
}