import { StyleSheet, Text, TextInput, View } from 'react-native'
import React from 'react'
import { GradientButton3 } from '../../components'
import { Picker } from '@react-native-community/picker'
import { Units } from '../../constants/Unit'
import { useState } from 'react'
import { renderLevel } from '../../constants/Config'

const FilterComp = ({
    selectLevel,
    custom,
    topics,
    ques,
    btn,
    fetchQuestionAnaliticsData,
    selectedIndexLevel,
    setSelectedIndexLevel,
    selectedIndexLesson,
    setSelectedIndexLesson,
    selectedIndexTopic,
    setSelectedIndexTopic,
    filterQuestionNo,
    setFilterQuestionNo,
    filter,
    limit,
    byCustomLesson
}) => {

    const handlePress = () => {
        console.log("first-", selectedIndexLevel)
        if (!byCustomLesson) {
            if (selectedIndexLevel !== -1) {
                filter.LevelId = renderLevel[selectedIndexLevel]
            } else {
                delete filter.LevelId
            }
            if (selectedIndexLesson !== -1) {
                filter.CustomLessonID = selectedIndexLesson
            } else {
                delete filter.CustomLessonID
            }
            if (selectedIndexTopic !== -1) {
                filter.CustomTopicID = selectedIndexTopic
            } else {
                delete filter.CustomTopicID
            }
            if (filterQuestionNo && filterQuestionNo?.length) {
                filter.filterQuestionNo = filterQuestionNo
            } else {
                delete filter.filterQuestionNo
            }
            fetchQuestionAnaliticsData(limit, filter)
        } else {
            console.log("firstdfs", parseInt(selectedIndexLevel) + 1);
            fetchQuestionAnaliticsData(parseInt(selectedIndexLevel) + 1)
        }
    }

    return (
        <View style={styles.container}>
            {selectLevel ? (
                <View style={styles.wrapper}>
                    <Text style={styles.title}>Select Level</Text>
                    <Picker
                        selectedValue={selectedIndexLevel}
                        onValueChange={(_h) => {
                            setSelectedIndexLevel(_h)
                        }}
                        style={styles.picker}
                        mode="dropdown"
                    >
                        <Picker.Item label={""} value={-1} key={"blank"} />
                        {renderLevel.map((item, index) => {
                            return (
                                <Picker.Item label={item.Name} value={index} key={index.toString()} />
                            )
                        })}
                    </Picker>
                </View>
            ) : null}
            {custom ? (
                <View style={styles.wrapper}>
                    <Text style={styles.title}>Select Custom Lesson</Text>
                    <Picker
                        selectedValue={selectedIndexLesson}
                        onValueChange={(_h) => {
                            setSelectedIndexLesson(parseInt(_h))
                        }}
                        style={styles.picker}
                        mode="dropdown"
                    >
                        <Picker.Item label={""} value={-1} key={"blank"} />
                        {Array(28).fill(0).map((_, i) => {
                            return (
                                <Picker.Item label={i + 1} value={i + 1} key={i} />
                            )
                        })}
                    </Picker>
                </View>
            ) : null}
            {topics ? (
                <View style={styles.wrapper}>
                    <Text style={styles.title}>Select Custom Topics</Text>
                    <Picker
                        selectedValue={selectedIndexTopic}
                        onValueChange={(_h) => {
                            setSelectedIndexTopic(parseInt(_h))
                        }}
                        style={styles.picker}
                        mode="dropdown"
                    >
                        <Picker.Item label={""} value={-1} key={"blank"} />
                        {Array(200).fill(0).map((_, i) => {
                            return (
                                <Picker.Item label={i + 1} value={i + 1} key={i} />
                            )
                        })}
                    </Picker>
                </View>
            ) : null}
            <View style={{
                flexDirection: "row",
                alignItems: "baseline"
            }}>
                {ques ? (
                    <View style={{
                        width: 280,
                        marginTop: 15,
                        marginRight: '13%'
                    }}>
                        <Text style={styles.title}>Search by Question Number</Text>
                        <TextInput
                            placeholder={"Number Selection"}
                            value={filterQuestionNo}
                            onChangeText={(txt) => {
                                setFilterQuestionNo(txt)
                            }}
                            style={styles.picker}
                            placeholderStyle={{
                                fontFamily: Units.ftFamily.MontserratR,
                                fontSize: 14
                            }}
                        />
                    </View>
                ) : null}
                {btn ? (
                    <GradientButton3
                        text="Search"
                        handlePress={handlePress}
                        width={116}
                        style={{
                            marginTop: 45
                        }}
                    />
                ) : null}
            </View>
        </View>
    )
}

export default FilterComp

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        paddingHorizontal: 27,
        marginBottom: 10,
        marginTop: 25
    },
    wrapper: {
        width: 280,
        marginTop: 15
    },
    title: {
        fontFamily: Units.ftFamily.MontserratB,
        fontSize: 17,
        marginBottom: 10,
        color: "#010A41"
    },
    picker: {
        width: '100%',
        paddingHorizontal: 10,
        paddingVertical: 9,
        borderWidth: 1,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.27,
        shadowRadius: 4.65,
        elevation: 6,
        backgroundColor: "#fff",
        borderColor: "#fff",
        borderRadius: 25,
    }
})