import { StyleSheet, Text, TextInput, View } from 'react-native'
import React from 'react'
import { Units } from '../../constants/Unit'

const QuesInput = ({
    title,
    style,
    textInputStyle,
    multiline,
    value,
    onChangeText,
    placeholder
}) => {
  const [error, setError] = React.useState("");
  const oneLine = title.includes("Question Number") || title.includes("Lesson ID") || title.includes("Topic ID");
  const numeric = title.includes("Lesson ID") || title.includes("Topic ID");

  const validateInputs = (text) => {
    let numreg = /^[0-9]+$/;
    if(numeric) {
      if(text.length === 0) {
        setError("");
        onChangeText(null);
      } else if (numreg.test(text)) {
        setError("");
        let num = parseInt(text)
        onChangeText(num);
      } else {
        setError("Must Be a Number");
        let num = parseInt(text)
        if(!Number.isNaN(num)) {
          onChangeText(num);
        }
      }
    } else {
      onChangeText(text);
    }
  }
  return (
    <View style={[styles.constainer, style ? style : {}]}>
        <View style={{
          backgroundColor: "#010A41",
          width: "23%",
          paddingLeft: 15,
          height: "100%",
          justifyContent: "center"
        }}>
          <Text style={{
            color: "#E2E8F0",
            fontFamily: Units.ftFamily.MontserratR,
            fontSize: 17,
            paddingLeft: 20
          }}>{title}</Text>
        </View>
        <View style={{
          backgroundColor: "#fff",
          height: "100%",
          width: "77%",
          justifyContent: "center",
        }}>
          <TextInput
            style={[styles.textInput, textInputStyle ? textInputStyle : {}]}
            placeholder={placeholder ? placeholder : ""}
            multiline={multiline ? true : false}
            value = {value}
            keyboardType = {numeric ? "numeric" : "default"}
            onChangeText={(text)=> validateInputs(text)}
          />
        </View>
      </View>
  )
}

export default QuesInput

const styles = StyleSheet.create({
    constainer: {
        flexDirection: "row",
        borderRadius: 30,
        alignItems: "center",
        borderRadius: 20,
        overflow: "hidden",
        height: 45,
        backgroundColor: "#fff"
    },
    textInput: {
        height: "100%",
        paddingHorizontal: 20,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
    }
})