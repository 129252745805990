import firebase from "firebase";
import {
  REACT_APP_API_KEY, REACT_APP_AUTH_DOMAIN,
  REACT_APP_DATABASE_URL, REACT_APP_PROJECT_ID,
  REACT_APP_STORAGE_BUCKET, REACT_APP_MESSAGING_SENDER_ID,
  REACT_APP_APP_ID, REACT_APP_MEASUREMENT_ID
} from '@env';
import { Units } from "./Unit";
export const tableNames = {
  level: "Levels",
  section: "Sections",
  question: "Questions",
  lessonForRFU: "LessonForReportsForUser",
  questionForRFU: "QuestionsForReportsForUser",
  practiceForRFU: "PracticeForReportsForUser",
  userClickedOnPremium: "hasUserClickedOnPremium",
  appsFlyerUserEvent: "AppsFlyerUserEvent",
  usersForAppDownloads: "UsersForAppDownloads",
  isContentDownloaded: "IsContentDownloaded",
  inAppReview: "InAppReviewForUser",
  eventsCount: "NumberOfEvents",
  userProfiles: "UserProfiles",
  enrollment: "Enrollments",
  aggregateQuestionData: "AggregateQuestionDataV2",
  aggregatePracticeTestResults: "AggregatePracticeTestResultsV2",
  customLessonMapping: "CustomLessonMapping"
};

export const webhookUrl =
  "https://hooks.zapier.com/hooks/catch/3401702/ox6nfdd/";

export const onlineUrl = Units.isIOS
  ? "https://hamradioprep.com/mobile?utm_source=ios&utm_medium=mobile&utm_campaign=react"
  : "https://hamradioprep.com/mobile?utm_source=android&utm_medium=mobile&utm_campaign=react";
export const findExamUrl =
  "https://www.arrl.org/find-an-amateur-radio-license-exam-session";
export const contactUsUrl = Units.isIOS
  ? "http://hamradioprep.com/mobile-app-support?utm_source=ios&utm_medium=mobile&utm_campaign=react"
  : "http://hamradioprep.com/mobile-app-support?utm_source=android&utm_medium=mobile&utm_campaign=react";
export const licenseUrl = Units.isIOS
  ? "https://hamradioprep.com/how-to-get-your-ham-radio-license-made-easy?utm_source=ios&utm_medium=mobile&utm_campaign=react"
  : "https://hamradioprep.com/how-to-get-your-ham-radio-license-made-easy?utm_source=android&utm_medium=mobile&utm_campaign=react";

export const firebaseConfig = {
  apiKey: REACT_APP_API_KEY,
  authDomain: REACT_APP_AUTH_DOMAIN,
  databaseURL: REACT_APP_DATABASE_URL,
  projectId: REACT_APP_PROJECT_ID,
  storageBucket: REACT_APP_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
  appId: REACT_APP_APP_ID,
  measurementId: REACT_APP_MEASUREMENT_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const auth = firebase.auth();

export const appsflyerConf = {
  devKey: "QLNwzyRVL4FvhA5or3s6ZU",
  isDebug: false,
  appId: "id1525090989",
  onInstallConversionDataListener: false,
  onDeepLinkListener: false,
};

export const eventForPremium = {
  eventName: "premium_course",
  eventValue: {
    content: "User visit a premium online course",
  },
};

export const eventForExam = {
  eventName: "final_exam_finished",
  eventValue: {
    content: "User finished a first final exam",
  },
};

export const eventForQuizFinished = {
  eventName: "quiz_finished",
  eventValue: {
    content: "User finished the quiz",
  },
};

export const videoUrl = "FindAnExam.mp4";

export const events = {
  noOfDownloads: "NumberOfDownloads",
  noOfLoginSucceed: "NumberOfLoginSucceed",
  noOfQuizFinished: "NumberOfQuizFinished",
  noOfPractice: "NumberOfPracticeFinished",
  noOfPremium: "NumberOfPremiumCourse",
};

export const storageImages = [
  "T1.jpeg",
  "T2.jpeg",
  "T3.jpeg",
  "E501.png",
  "E601.png",
  "E602.png",
  "E603.png",
  "E701.png",
  "E702.png",
  "E703.png",
  "E901.png",
  "E902.png",
  "E903.png",
  "General_Diagram.jpg",
  "FindAnExam.mp4",
];

export const renderLevel = [
  {
    LevelId: 1,
    Name: "Technician License",
    ShortDesc: "Updated for 2022",
    id: "uDBNGCjyqit98hECx24v",
  },
  // {
  //   LevelId: 2,
  //   Name: "General License",
  //   ShortDesc: "Prerequisite: Technician",
  //   id: "7yPtaVP9F1QjPJonol4q"
  // },
  {
    LevelId: 3,
    Name: "Amateur Extra License",
    ShortDesc: "The final class.",
    id: "3UZQCmKtSKO2kBY1FXcc",
  },
  {
    LevelId: 4,
    Name: "Technician License (2022 - 2026)",
    ShortDesc: "Beginners start here!",
    Status: true,
    id: "uDBNGCjyqit98hECx24v-new",
  },
  {
    LevelId: 5,
    Name: "General License (2023 - 2027)",
    ShortDesc: "Upgrade to General and expand your privileges.",
    Status: true,
    id: "7yPtaVP9F1QjPJonol4q-new",
  },
  {
    LevelId: 6,
    Name: "Amateur Extra (2024 - 2028)",
    ShortDesc: "The final class.",
    Status: true,
    id: "d0891411db834651a53baa679477e7bd-new",
  },
]

export const ADMIN_USER = ["criticalcommunicationsmedia@gmail.com", "jamesbluebandit@yahoo.com"];

export const authInfo = {
  email: "test@user.com",
  password: "123456789",
};
