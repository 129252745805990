import * as React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Picker } from '@react-native-community/picker';
import { EDColors } from '../constants/Colors';
import { Units } from '../constants/Unit';

const Dropdown = ({ isMobile, data, handlePress, text, curIndex, blank }) => {
    return (
        <View
            style={[styles.selectSubCon, {
                justifyContent: isMobile ? "space-between" : "flex-start",
                marginRight: text === "Select Level:" && !isMobile ? 30 : 0,
            }]}
            key={text}
        >
            <Text style={[styles.quelenTxt, { marginRight: 10 }]}>{text}</Text>
            <Picker
                selectedValue={curIndex}
                onValueChange={(hand) => handlePress(hand)}
                style={styles.picker}
                mode="dropdown"
            >
                {blank && <Picker.Item label={""} value={-1} key={"blank"} />}
                {
                    data.map((item, index) => {
                        return (
                            <Picker.Item label={item.Name} value={index} key={index.toString()} />
                        )
                    })
                }
            </Picker>
        </View>
    );
}

const styles = StyleSheet.create({
    selectSubCon: {
        flexDirection: "row",
        alignItems: "center",
        marginTop: 10,
    },
    quelenTxt: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: Units.ftSizes.default,
        color: EDColors.gradient[0],
        minWidth: 110,
    },
    picker: {
        paddingRight: 5,
        paddingVertical: 5,
        flex: 1,
        paddingHorizontal: 10,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: EDColors.topbar,
        width: 180,
    }
})
export default Dropdown;