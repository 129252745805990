import { Pressable, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { AntDesign } from '@expo/vector-icons'
import { Units } from '../../constants/Unit'

const mainTab = ({
    selectedMainTab,
    setSelectedMainTab,
    onDeletePressed
}) => {
  return (
    <View style={{
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        marginVertical: 17,
        position: "relative"
      }}>
        <Pressable style={{
          backgroundColor: selectedMainTab === "User Info" ? "#0243DD" : "transparent",
          borderRadius: 12
        }} onPress={()=>{
          setSelectedMainTab("User Info")
        }}>
          <Text style={{
            fontFamily: Units.ftFamily.MontserratB,
            fontSize: 20,
            color: selectedMainTab === "User Info" ? "#fff" : "#010A41",
            paddingVertical: 15,
            paddingHorizontal: 25
          }}>User Info</Text>
        </Pressable>
        <Pressable style={{
          backgroundColor: selectedMainTab === "Enrollments" ? "#0243DD" : "transparent",
          borderRadius: 12
        }} onPress={()=>{
          setSelectedMainTab("Enrollments")
        }}>
          <Text style={{
            fontFamily: Units.ftFamily.MontserratB,
            fontSize: 20,
            color: selectedMainTab === "Enrollments" ? "#fff" : "#010A41",
            paddingVertical: 15,
            paddingHorizontal: 25
          }}>Enrollments</Text>
        </Pressable>
        <Pressable style={{
          backgroundColor: selectedMainTab === "Exam Readiness" ? "#0243DD" : "transparent",
          borderRadius: 12
        }} onPress={()=>{
          setSelectedMainTab("Exam Readiness")
        }}>
          <Text style={{
            fontFamily: Units.ftFamily.MontserratB,
            fontSize: 20,
            color: selectedMainTab === "Exam Readiness" ? "#fff" : "#010A41",
            paddingVertical: 15,
            paddingHorizontal: 25,
          }}>Exam Readiness</Text>
        </Pressable>
        {selectedMainTab === "User Info" ? (
          <Pressable style={{
            position: "absolute",
            right: 40,
            backgroundColor: "#fff",
            shadowOffset: { width: 1, height: 2 },
            shadowColor: "black",
            shadowOpacity: .3,
            shadowRadius: 5,
            elevation: 3,
            width: 45,
            height: 45,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 4
          }} onPress={onDeletePressed}>
            <View style={{
              backgroundColor: "#0243DD",
              borderRadius: 16,
              height: 33,
              width: 33,
              justifyContent: "center",
              alignItems: "center"
            }}>
              <AntDesign name="delete" size={20} color="white" />
            </View>
          </Pressable>
        ) : (null)}
      </View>
  )
}

export default mainTab

const styles = StyleSheet.create({})