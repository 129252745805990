import React, { useEffect, useRef, useState } from 'react';
import {
  View,
  Text,
  ActivityIndicator,
  TouchableOpacity,
  Dimensions,
  TextInput,
  Pressable,
  StyleSheet,
  Alert
} from 'react-native';

import { useMediaQuery } from 'react-responsive';

import { ScreenHeader, Dropdown2, InputWithName2, GradientButton3 } from '../../components';
import styles from './style';
import { EDColors } from '../../constants/Colors';

import Answer from '../answer';
import { AntDesign, Feather, FontAwesome, Ionicons } from '@expo/vector-icons';
import { ScrollView } from 'react-native-gesture-handler';

import SideBar from '../../components/sidebar';



import Header from '../../components/Header';

import { enrollmentHandler, getAllLessons, getUserEnrollmentInfo, getUserExamResultInfo, updateEnrollment, updateLessonLastUpdatedTime } from '../../actions/OfflineAction';
import firebase, { firestore } from 'firebase';
import { tableNames } from '../../constants/Config';
import LottieView from 'lottie-react-native';
import { toast } from 'react-toastify';
import axios from 'axios';
import Modal from '../../components/Modal';
import ModalWithInput from '../../components/ModalWithInput';
import { Video } from 'expo';
var groupBy = require('lodash.groupby');

const loadingAnimation = require('../../assets/lottieAnimations/98770-assistagro-loading-bars.json');


const LevelUpdate = ({ navigation }) => {
  const [loading, setLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [activeLessonIds, setActiveLessonIds] = useState([]);

  const [levels, setLevels] = useState(null);
  const [lessonVideos, setLessonVideos] = useState({});


  useEffect(() => {
    fetchAllLessons()
  }, [])

  const fetchAllLessons = async () => {
    setLoading(true)
    try {
      const allLessons = await getAllLessons()
      //get RawVideo for each lesson and save it with a key of levelId-LessonId in lessonVideos
      const videoLinks = {}
      allLessons.forEach(lesson => {
        videoLinks[`${lesson.LevelId}_${lesson.CustomLessonId}`] = lesson.RawVideo
      })
      console.log("Lesson Videos", lessonVideos)
      setLessonVideos(videoLinks)
      const groupedLessons = groupBy(allLessons, "LevelId")
      //iterate over the groupedLessons and sort the lessons by CustomLessonId
      for (let key in groupedLessons) {
        groupedLessons[key].sort((a, b) => a.CustomLessonId - b.CustomLessonId)
      }

      setLevels(groupedLessons)
    } catch (e) {
      console.log("Error in fetching lessons", e)
    }
    setLoading(false)


  }

  const updateLessons = async (idList) => {
    console.log("Update Lessons", idList)
    await updateLessonLastUpdatedTime(idList)
    fetchAllLessons()
    setConfirmationModal(false)
  }

  const getLevelNames = (levelId) => {
    if (levelId === "4") {
      return "Level 1: Technician"
    } else if (levelId === "5") {
      return "Level 2: General"
    } else if (levelId === "6") {
      return "Level 3: Amateur"

    }

  }

  return (
    <View style={[styles.container, { maxHeight: window.height, flexDirection: "row" }]}>
      {/* <GradientButton3 
        text="Add Sectio Data For Level4"
        handlePress={()=>handleAddSectionDataForLevel4()}
      /> */}
      <SideBar navigation={navigation} />
      {/* <dashStack.Navigator> */}
      <>
        <ScrollView contentContainerStyle={styles.scrollView}>
          <Header />
          <Text style={styles.title}>Update Lessons</Text>
          {
            loading ? (
              <View style={styles.loadingWrapper}>
                <ActivityIndicator size="large" color={EDColors.gradient[1]} />
              </View>
            ) : (levels && Object.keys(levels).map((levelId, index) => {
              return (
                <View key={index} style={styles2.levelContainer}>
                  <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 15 }}>
                    <Text style={styles2.levelTitle}>{getLevelNames(levelId)}</Text>
                    <TouchableOpacity onPress={() => {
                      const allLessonIdAndVideo = levels[levelId].map(lesson => {
                        return {
                          id: lesson.id,
                          VideoURL: lessonVideos[`${levelId}_${lesson.CustomLessonId}`]
                        }
                      })
                      console.log("Update all lessons", allLessonIdAndVideo)
                      setActiveLessonIds(allLessonIdAndVideo)
                      setConfirmationModal(true)
                    }} style={{ backgroundColor: EDColors.primary, paddingHorizontal: 15, paddingVertical: 5, borderRadius: 10, marginLeft: 30 }}>
                      <Text>Update All {levels[levelId].length} Lessons</Text>
                    </TouchableOpacity>
                  </View>
                  <View style={styles2.lessonsContainer}>
                    {levels[levelId].map((lesson, index) => {
                      const lessonVideoLink = lessonVideos[`${levelId}_${lesson.CustomLessonId}`]
                      return (
                        <View key={index} style={styles2.lessonContainer}>
                          <View style={{
                            alignSelf: 'flex-start',
                          }}>
                            <Text style={styles2.lessonTitle}>Lesson {lesson.CustomLessonId}: {lesson.Name}</Text>
                            <Text style={styles2.lessonTitle}>Last Updated:  {new Date(lesson.LastUpdate?.seconds * 1000).toLocaleString()}</Text>
                            <Text style={styles2.lessonTitle}>Video URL:</Text>
                            <TextInput
                              value={lessonVideoLink}
                              multiline
                              style={{
                                color: '#fff',
                                borderWidth: 1,
                                borderColor: lessonVideoLink === lesson.RawVideo ? '#fff' : 'orange',
                                paddingVertical: 5,
                                paddingHorizontal: 10,
                                borderRadius: 5,
                                width: '100%',
                                flexGrow: 1,
                                flexShrink: 1,
                                height: 120

                              }}

                              textAlignVertical="top"

                              onChangeText={(text) => {
                                const newLessonVideos = { ...lessonVideos }
                                newLessonVideos[`${levelId}_${lesson.CustomLessonId}`] = text
                                setLessonVideos(newLessonVideos)
                              }}
                            />
                          </View>
                          <TouchableOpacity onPress={() => {
                            console.log("Update lesson", lesson)

                            setActiveLessonIds([{
                              id: lesson.id,
                              VideoURL: lessonVideoLink
                            }])
                            setConfirmationModal(true)
                          }} style={{ backgroundColor: EDColors.primary, paddingHorizontal: 15, paddingVertical: 5, borderRadius: 10 }}>
                            <Text>Update Now</Text>
                          </TouchableOpacity>
                        </View>
                      )
                    })}
                  </View>
                </View>
              )
            }
            )
            )
          }


        </ScrollView>

        {confirmationModal ? (
          <Modal
            handleClick={
              () => {
                console.log("Active Lesson Ids MODAL", activeLessonIds)
                updateLessons(activeLessonIds)
              }
            }
            height={310}
            setModalOpen={setConfirmationModal}
            message={`Are you sure you want to make this update? this will trigger an update on the mobile app`}
            handleClose={() => {
              setConfirmationModal(false);
            }}
          />

        ) : null}
      </>
    </View>
  )
};

export default LevelUpdate;

const styles2 = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: EDColors.background,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: "row"
  },
  scrollView: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: 20,
    flexDirection: "column"
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    color: EDColors.text,
    marginBottom: 20
  },
  levelContainer: {
    width: "100%",
    padding: 10,
    backgroundColor: EDColors.white,
    borderRadius: 5,
    marginBottom: 20
  },
  levelTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: EDColors.text,
    marginBottom: 10
  },
  lessonsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between"
  },
  lessonContainer: {
    width: "48%",
    padding: 10,
    backgroundColor: EDColors.pgbarbk,
    borderRadius: 5,
    marginBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  lessonTitle: {
    fontSize: 14,
    fontWeight: "bold",
    color: EDColors.white,
    marginBottom: 5
  },
  lessonDesc: {
    fontSize: 14,
    color: EDColors.white
  },
  loadingWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  }
})
