import { Pressable, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { Feather, Ionicons } from '@expo/vector-icons'
import { useState } from 'react'
import firebase from 'firebase'
import { useNavigation } from '@react-navigation/native'

const Header = () => {
  const navigation = useNavigation()
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const [isSignOutHovered, setIsSignOutHovered] = useState(false)
  return (
    <View style={{
        backgroundColor: "#fff",
        height: 70,
        width: '100%',
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center"
    }}>
        <Ionicons name="notifications-outline" size={24} color="black" />
        <View style={{backgroundColor: "#13E1FD", borderRadius: 20, height: 40, width: 40, marginRight: 12, marginLeft: 30}} />
        <Text>Admin Account (test)</Text>
        <Pressable style={{
          backgroundColor: isDropdownVisible ? "#eee" : "tranparent",
          marginRight: 25,
          marginLeft: 8,
          height: 40,
          width: 40,
          borderRadius: 20,
          justifyContent: "center",
          alignItems: "center",
          position: "relative"
        }} onHoverIn={()=>setIsDropdownVisible(true)} onHoverOut={()=>setIsDropdownVisible(false)}>
          <Feather name="chevron-down" size={20} color="black" style={{}} />
          <View style={{
            position: "absolute",
            top: 38,
            right: 0,
            display: isDropdownVisible ? "flex" : "none"
          }}>
            <Pressable style={{
              backgroundColor: isSignOutHovered ? "#eee" : "#fff",
              shadowOffset: { width: 1, height: 2 },
              shadowColor: "black",
              shadowOpacity: .3,
              shadowRadius: 5,
              elevation: 3,
              paddingVertical: 10,
              paddingHorizontal: 25,
              borderRadius: 7
            }} onHoverIn={()=>{
              setIsDropdownVisible(true);
              setIsSignOutHovered(true)
            }} onHoverOut={()=>{
              setIsSignOutHovered(false);
              setIsDropdownVisible(false)
            }} onPress={()=>{
              firebase.auth().signOut()
              navigation.navigate('Login');
            }}>
              <Text>SignOut</Text>
            </Pressable>
          </View>
        </Pressable>
    </View>
  )
}

export default Header

const styles = StyleSheet.create({})