import { StyleSheet, Text, TextInput, View } from 'react-native'
import React from 'react'
import { Units } from '../../constants/Unit'
import { Picker } from '@react-native-community/picker'

const QuesDropDown = ({
    title,
    style,
    dropDownStyle,
    blank,
    section,
    handlePress,
    curIndex,
    data
}) => {
  return (
    <View style={[styles.constainer, style ? style : {}]}>
        <View style={styles.left}>
          <Text style={styles.title}>{title}</Text>
        </View>
        <View style={{
          backgroundColor: "#fff",
          height: "100%",
          width: "77%",
          justifyContent: "center",
        }}>
            <Picker
                selectedValue={curIndex}
                onValueChange={(hand) => handlePress(hand)}
                style={[styles.textInput, dropDownStyle ? dropDownStyle : {}]}
                mode="dropdown"
            >
                {blank && <Picker.Item label={""} value={-1} key={"blank"}/>}
                {
                    data && data.map((item, index) => {
                        if( section && index > 9 ) return null;
                        return(
                            <Picker.Item label={section ? item?.SectionId : item?.Name} value={index} key={index.toString()}/>
                        )
                    })
                }
            </Picker>
          {/* <TextInput
            style={[styles.textInput, textInputStyle ? textInputStyle : {}]}
            placeholder="Technician License (2022 - 2026)"
            multiline={multiline ? true : false}
          /> */}
        </View>
      </View>
  )
}

export default QuesDropDown

const styles = StyleSheet.create({
    constainer: {
        flexDirection: "row",
        borderRadius: 30,
        alignItems: "center",
        borderRadius: 20,
        overflow: "hidden",
        height: 45,
        backgroundColor: "#fff"
    },
    left: {
        backgroundColor: "#010A41",
        width: "23%",
        paddingLeft: 15,
        height: "100%",
        justifyContent: "center"
    },
    title: {
        color: "#E2E8F0",
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: 17,
        paddingLeft: 20
    },
    textInput: {
        height: "100%",
        paddingHorizontal: 20,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        borderColor: "transparent"
    }
})