import { Pressable, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import GradientButton3 from './GradientButton3'
import { AntDesign } from '@expo/vector-icons'
import { Units } from '../constants/Unit'

const Modal = ({
  handleClick,
  setModalOpen,
  message,
  handleClose,
  height
}) => {
  return (
    <View style={styles.bg}>
      <View style={[styles.container, height ? { height: height } : {}]}>
        <Pressable style={styles.closeBtn} onPress={() => {
          setModalOpen(false)
        }}>
          <AntDesign name="closecircle" size={24} color="red" />
        </Pressable>
        <Text style={styles.mainTxt}>{message}</Text>
        <View style={styles.buttonWrapper}>
          <Pressable style={styles.button} onPress={handleClose}>
            <Text style={styles.buttonTxt}>
              Cancel
            </Text>
          </Pressable>
          <GradientButton3
            text="Confirm"
            handlePress={handleClick}
            width={'49%'}
          />
        </View>
      </View>
    </View>
  )
}

export default Modal

const styles = StyleSheet.create({
  bg: {
    position: "fixed",
    backgroundColor: "#32323239",
    height: '100%',
    width: '100%',
    justifyContent: "center",
    alignItems: "center"
  },
  container: {
    height: 160,
    width: 310,
    backgroundColor: "#fff",
    borderRadius: 12,
    paddingVertical: 30,
    paddingHorizontal: 25,
    position: "relative"
  },
  closeBtn: {
    position: "absolute",
    top: -8,
    right: -8
  },
  mainTxt: {
    marginBottom: 19,
    fontSize: 19,
    fontFamily: Units.ftFamily.MontserratB,
    textAlign: "center"
  },
  buttonWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  button: {
    width: "49%"
  },
  buttonTxt: {
    fontSize: 18,
    textAlign: "center",
    fontFamily: Units.ftFamily.MontserratR,
  }
})