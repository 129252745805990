import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { Units } from '../../constants/Unit'

const FieldComponent = ({
    title,
    data
}) => {
  return (
    <Text style={{color: "#010A41", fontFamily: Units.ftFamily.MontserratB, fontSize: 18}}>
        {title} {" "}<Text style={{fontFamily: Units.ftFamily.MontserratR}}>{data}</Text>
    </Text>
  )
}

export default FieldComponent

const styles = StyleSheet.create({})