import { t } from '../actions/Type';

const initialState = {
    authLoading:  false,
    userInfo: null,

    isConnected: true,
}

export default function( state = initialState, action ) {
    switch( action.type ) {
        case t.AUTH_LOAD_SUCCESS:
            return {
                ...state,
                userInfo: action.payload,
            };
        case t.NETWORK_CONNECTED_STATE:
            return {
                ...state,
                isConnected: action.payload,
            };
        default:
            return state;
    }
}