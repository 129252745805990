import React from 'react';
import {
    View,
    FlatList,
    Text,
} from 'react-native';

import styles from './style';
import QuesBlock from './quesBlock';
import { GradientButton3 } from '../../components';
import QuesData from '../study/quesData';
import { Units } from '../../constants/Unit';

const limit = 5;
class Answer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            renderData: [],
            isLoading: true,
            data: [],
            localChangedItems: props.changedItems,
        }
    }

    componentDidMount() {
        this.setState({
            isLoading: false,
            page: 0,
            data: this.props.quesData
        }, function () {
            this.addRecords(0);
        });
    }

    componentDidUpdate(prevPros, prevState) {
        if (prevPros.quesData !== this.props.quesData) {
            this.setState({
                isLoading: false,
                page: 0,
                data: this.props.quesData
            }, function () {
                this.addRecords(0);
            });
        }
        if (prevPros.changedItems !== this.props.changedItems) {
            this.setState({ localChangedItems: this.props.changedItems });
        }
    }

    addRecords = (_p) => {
        const newRecords = [];
        for (var i = _p * limit, i1 = i + limit; i < i1 && i < this.state.data.length; i++) {
            newRecords.push(this.state.data[i]);
        }
        _p === 0 ?
            this.setState({
                renderData: [...newRecords]
            }) :
            this.setState({
                renderData: [...this.state.renderData, ...newRecords]
            });
    }

    onScrollHandler = () => {
        this.setState({
            page: this.state.page + 1
        }, () => {
            this.addRecords(this.state.page);
        })
    }


    // _mainRenderItem = ({ item, index }) => {
    //     const { levelData, isMobile, sectionData, deleteItem, updateItem, setChangedItems } = this.props;
    //     return <QuesData item={item} lData={levelData} sData={sectionData} isMobile={isMobile} deleteItem={deleteItem} updateItem={updateItem} setChangedItems={setChangedItems} changedItems={this.state.localChangedItems} />
    //     // return <QuesBlock item={item} lData={levelData} sData = {sectionData} isMobile={isMobile} deleteItem={deleteItem} updateItem={updateItem} />
    // }

    render() {
        const { quesData, levelData, isMobile, sectionData, deleteItem, updateItem, setChangedItems, changedItems } = this.props;

        return (
            <View style={{}} >
                {this.state.renderData.length === 0 ? (
                    <Text style={{
                        textAlign: "center",
                        marginTop: 20,
                        fontFamily: Units.ftFamily.MontserratR
                    }}>No Items Found</Text>
                ) : (null)}
                {!this.state.isLoading &&
                    <FlatList
                        data={this.state.renderData}
                        keyExtractor={(item, index) => item.id + index.toString()}
                        renderItem={({ item, index }) => (
                            <QuesData item={item} lData={levelData} sData={sectionData} isMobile={isMobile} deleteItem={deleteItem} updateItem={updateItem} setChangedItems={setChangedItems} changedItems={this.state.localChangedItems} />
                        )}
                        showsVerticalScrollIndicator={false}

                    />}
                {this.state.renderData.length < quesData.length && <View style={{ width: isMobile ? '80%' : '50%', marginTop: 20, marginBottom: 80, alignSelf: 'center' }}>
                    <GradientButton3
                        text={"Load More"}
                        handlePress={this.onScrollHandler}
                    />
                </View>}
            </View>
        );
    }

};

export default Answer;
