import { Pressable, StyleSheet, Text, TextInput, View } from 'react-native'
import React from 'react'
import GradientButton3 from './GradientButton3';
import { Units } from '../constants/Unit';
import { toast } from 'react-toastify';
import { updateEnrollment } from '../actions/OfflineAction';
import { AntDesign } from '@expo/vector-icons';

const ModalWithInput = ({
    setModalOpen,
    setCurrentProductId,
    userFirstName,
    setUserFirstName,
    userLastName,
    setUserLastName,
    userEmail,
    setUserEmail,
    currentProductId,
    userData
}) => {
    return (
        <View style={{
            position: "fixed",
            backgroundColor: "#32323239",
            height: '100%',
            width: '100%',
            justifyContent: "center",
            alignItems: "center"
        }}>
            <View style={{
                height: 315,
                width: 370,
                backgroundColor: "#fff",
                borderRadius: 12,
                paddingVertical: 30,
                paddingHorizontal: 40,
                position: "relative"
            }}>
                <Pressable style={{
                    position: "absolute",
                    top: -8,
                    right: -8
                }} onPress={() => {
                    setModalOpen(false);
                    setCurrentProductId(null)
                }}>
                    <AntDesign name="closecircle" size={24} color="red" />
                </Pressable>
                <Text style={{
                    marginBottom: 19,
                    fontSize: 17,
                    fontFamily: Units.ftFamily.MontserratB,
                }}>Insert Name to Access on Ham Radio Website</Text>
                <TextInput
                    value={userFirstName}
                    onChangeText={(txt) => setUserFirstName(txt)}
                    placeholder="First Name"
                    style={styles.modalInputs}
                />
                <TextInput
                    value={userLastName}
                    onChangeText={(txt) => setUserLastName(txt)}
                    placeholder="Last Name"
                    style={styles.modalInputs}
                />
                <TextInput
                    value={userEmail}
                    onChangeText={(txt) => setUserEmail(txt)}
                    placeholder="Email"
                    style={[styles.modalInputs, {
                        marginBottom: 20
                    }]}
                />
                <GradientButton3
                    text="Submit"
                    handlePress={async () => {
                        try {
                            await updateEnrollment({
                                "first_name": userFirstName,
                                "last_name": userLastName,
                                "email": userData?.email,
                                "product_name": currentProductId
                            })
                            toast.success('User Data Successfully Saved');
                            setModalOpen(false)
                        } catch (e) {
                            console.log("ERROR SENDING THINKIFIC DATA", e)
                            toast.warning('Something went wrong');
                        }
                        setModalOpen(false)
                    }}
                    width={'100%'}
                />
            </View>
        </View>
    )
}

export default ModalWithInput

const styles = StyleSheet.create({
    modalInputs: {
        backgroundColor: "#fff",
        padding: 10,
        borderRadius: 20,
        width: '100%',
        marginRight: 30,
        shadowOffset: { width: 1, height: 2 },
        shadowColor: "black",
        shadowOpacity: .3,
        shadowRadius: 5,
        elevation: 3,
        marginBottom: 10
    }
})