import React from "react";

import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

// import bottom's main screens
import LoginScreen from './screens/login';
import StudyScreen from './screens/study';
import StudentInfo from "./screens/studentInfo";
import LevelUpdate from "./screens/levelupdate";

import AnalyzeResults from "./screens/analyzeResults";

const mainStack = new createStackNavigator();
function MainStack() {
    return (
        <NavigationContainer>
            <mainStack.Navigator>
                <mainStack.Screen name="Login" component={LoginScreen} options={{ headerShown: false }} />
                <mainStack.Screen name="Admin" component={StudyScreen} options={{ headerShown: false }} />
                <mainStack.Screen name="StudentInfo" component={StudentInfo} options={{ headerShown: false }} />
                <mainStack.Screen name="LevelUpdate" component={LevelUpdate} options={{ headerShown: false }} />
                <mainStack.Screen name="AnalyzeResults" component={AnalyzeResults} options={{ headerShown: false }} />
            </mainStack.Navigator>
        </NavigationContainer>
    )
};

export default MainStack;