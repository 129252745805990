import React from 'react';
import {
    ImageBackground,
    StyleSheet,
    Text,
} from 'react-native';
import { EDColors } from '../constants/Colors';
import { Assets } from '../constants/Images';
import { Units } from '../constants/Unit';

const ScreenHeader =({text, width, isMobile})=> {

    return (
        <ImageBackground source={Assets.laserbeam} style = {isMobile?[styles.imgbk,{height: 150}]:[styles.imgbk]} resizeMode="stretch">
            <Text style = {isMobile? [styles.descTxt, {width: width, fontSize: Units.mobftSizes.extra}] : [styles.descTxt, {width: width}]}>{text}</Text>
            {/* <Text style ={styles.levelTxt}>Select level:</Text> */}
        </ImageBackground>
    );
}

const styles = StyleSheet.create({
    imgbk: {
        width:'100%',
        height: 110,
    },
    descTxt:{
        marginTop: 20,
        fontFamily: Units.ftFamily.MontserratB,
        fontSize: Units.ftSizes.extra,
        color: EDColors.gradient[2],
        textAlign: 'center',
        paddingHorizontal: 50,
    },
    levelTxt: {
        fontSize: Units.ftSizes.big,
        fontFamily: Units.ftFamily.MontserratR,
        color: EDColors.white,
        textAlign: "center",
        lineHeight: 50,
    }
})
export default ScreenHeader;