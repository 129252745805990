import { View, Text, Image, Pressable } from 'react-native';
import React, { useEffect } from 'react';
import { Units } from '../constants/Unit';
import { FontAwesome5, Ionicons, MaterialIcons } from '@expo/vector-icons';
import { Assets } from '../constants/Images';
import { useRoute } from '@react-navigation/native';

const Sidebar = ({ navigation }) => {
    const route = useRoute();
    useEffect(() => {
        console.log(navigation);
        console.log(route.name);
    }, [])
    return (
        <View style={{
            // alignItems: "center"
        }}>
            <View style={{
                // width: "100%",
                height: 100,
                padding: 20
            }}>
                <Image source={Assets.titlelogo} style={{
                    height: 80,
                    width: 300,
                }} resizeMode="contain" />
            </View>
            <View style={{ width: "100%", height: 1, backgroundColor: "#777" }} />
            <View style={{ marginTop: 50 }}>
                <Pressable style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginVertical: 10,
                    position: "relative",
                    width: '100%'
                }} onPress={() => {
                    if (route.name !== 'Admin') {
                        navigation.navigate('Admin');
                    }
                }}>
                    <View style={{
                        display: route.name === "Admin" ? "flex" : "none",
                        backgroundColor: "#17F9DA",
                        width: 20,
                        height: 20,
                        borderBottomRightRadius: 5,
                        borderTopEndRadius: 5,
                        position: "absolute",
                        left: 0
                    }} />
                    <View style={{
                        width: '80%',
                        flexDirection: "row",
                        marginLeft: 45
                    }}>
                        <Ionicons name="grid" size={22} color={route.name === "Admin" ? "#3EB7D4" : "#fff"} />
                        <Text style={{ color: route.name === "Admin" ? "#3EB7D4" : "#fff", fontFamily: route.name === "Admin" ? Units.ftFamily.MontserratB : Units.ftFamily.MontserratR, marginLeft: 10, fontSize: 17, lineHeight: 24, width: "90%" }}>Question Pool</Text>
                    </View>
                </Pressable>
                <Pressable style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginVertical: 10,
                    position: "relative",
                    width: '100%'
                }} onPress={() => {
                    if (route.name !== 'LevelUpdate') {
                        navigation.navigate('LevelUpdate');
                    }
                }}>
                    <View style={{
                        display: route.name === "LevelUpdate" ? "flex" : "none",
                        backgroundColor: "#17F9DA",
                        width: 20,
                        height: 20,
                        borderBottomRightRadius: 5,
                        borderTopEndRadius: 5,
                        position: "absolute",
                        left: 0
                    }} />
                    <View style={{
                        width: '80%',
                        flexDirection: "row",
                        marginLeft: 45
                    }}>
                        <Ionicons name="cloud-upload" size={22} color={route.name === "LevelUpdate" ? "#3EB7D4" : "#fff"} />
                        <Text style={{ color: route.name === "LevelUpdate" ? "#3EB7D4" : "#fff", fontFamily: route.name === "LevelUpdate" ? Units.ftFamily.MontserratB : Units.ftFamily.MontserratR, marginLeft: 10, fontSize: 17, lineHeight: 24, width: "90%" }}>Lesson Updates</Text>
                    </View>
                </Pressable>
                <Pressable style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginVertical: 10,
                    position: "relative",
                    width: '100%'
                }} onPress={() => {
                    if (route.name !== 'AnalyzeResults') {
                        navigation.navigate('AnalyzeResults');
                    }
                }}>
                    <View style={{
                        display: route.name === "AnalyzeResults" ? "flex" : "none",
                        backgroundColor: "#17F9DA",
                        width: 20,
                        height: 20,
                        borderBottomRightRadius: 5,
                        borderTopEndRadius: 5,
                        position: "absolute",
                        left: 0
                    }} />
                    <View style={{
                        width: '80%',
                        flexDirection: "row",
                        marginLeft: 45
                    }}>
                        <MaterialIcons name="analytics" size={22} color={route.name === "AnalyzeResults" ? "#3EB7D4" : "#fff"} />
                        <Text style={{ color: route.name === "AnalyzeResults" ? "#3EB7D4" : "#fff", fontFamily: Units.ftFamily.MontserratR, marginLeft: 10, fontSize: 17, lineHeight: 24, width: "90%" }}>Analyze Results</Text>
                    </View>
                </Pressable>
                <Pressable style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginVertical: 10,
                    position: "relative",
                    width: '100%'
                }} onPress={() => {
                    if (route.name !== 'StudentInfo') {
                        navigation.navigate('StudentInfo');
                    }
                }}>
                    <View style={{
                        display: route.name === "StudentInfo" ? "flex" : "none",
                        backgroundColor: "#17F9DA",
                        width: 20,
                        height: 20,
                        borderBottomRightRadius: 5,
                        borderTopEndRadius: 5,
                        position: "absolute",
                        left: 0
                    }} />
                    <View style={{
                        width: '80%',
                        flexDirection: "row",
                        marginLeft: 45
                    }}>
                        <FontAwesome5 name="clipboard-list" size={22} color={route.name === "StudentInfo" ? "#3EB7D4" : "#fff"} style={{ marginLeft: 2 }} />
                        <Text style={{ color: route.name === "StudentInfo" ? "#3EB7D4" : "#fff", fontFamily: Units.ftFamily.MontserratR, marginLeft: 10, fontSize: 17, lineHeight: 24, width: "90%" }}>Student Info</Text>
                    </View>
                </Pressable>
                <Pressable style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginVertical: 10,
                    position: "relative",
                    width: '100%'
                }}>
                    <View style={{
                        display: route.name === null ? "flex" : "none",
                        backgroundColor: "#17F9DA",
                        width: 20,
                        height: 20,
                        borderBottomRightRadius: 5,
                        borderTopEndRadius: 5,
                        position: "absolute",
                        left: 0
                    }} />
                    <View style={{
                        width: '80%',
                        flexDirection: "row",
                        marginLeft: 45
                    }}>
                        <Ionicons name="mail" size={22} color="#fff" />
                        <Text style={{ color: "#fff", fontFamily: Units.ftFamily.MontserratR, marginLeft: 10, fontSize: 17, lineHeight: 24, width: "90%" }}>Verification Requests</Text>
                    </View>
                </Pressable>
                <Pressable style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginVertical: 10,
                    position: "relative",
                    width: '100%'
                }}>
                    <View style={{
                        display: route.name === null ? "flex" : "none",
                        backgroundColor: "#17F9DA",
                        width: 20,
                        height: 20,
                        borderBottomRightRadius: 5,
                        borderTopEndRadius: 5,
                        position: "absolute",
                        left: 0
                    }} />
                    <View style={{
                        width: '80%',
                        flexDirection: "row",
                        marginLeft: 45
                    }}>
                        <Ionicons name="person-outline" size={22} color="#fff" />
                        <Text style={{ color: "#fff", fontFamily: Units.ftFamily.MontserratR, marginLeft: 10, fontSize: 17, lineHeight: 24, width: "90%" }}>Personal</Text>
                    </View>
                </Pressable>
                <Pressable style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginVertical: 10,
                    position: "relative",
                    width: '100%'
                }}>
                    <View style={{
                        display: route.name === null ? "flex" : "none",
                        backgroundColor: "#17F9DA",
                        width: 20,
                        height: 20,
                        borderBottomRightRadius: 5,
                        borderTopEndRadius: 5,
                        position: "absolute",
                        left: 0
                    }} />
                    <View style={{
                        width: '80%',
                        flexDirection: "row",
                        marginLeft: 45
                    }}>
                        <Ionicons name="settings-outline" size={22} color="#fff" />
                        <Text style={{ color: "#fff", fontFamily: Units.ftFamily.MontserratR, marginLeft: 10, fontSize: 17, lineHeight: 24, width: "90%" }}>Setting</Text>
                    </View>
                </Pressable>
            </View>
        </View>
    )
}

export default Sidebar