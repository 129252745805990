import { StyleSheet } from 'react-native';
import { EDColors } from '../../constants/Colors';
import { Units } from '../../constants/Unit';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: EDColors.topbar,
        position: "relative"
    },
    scrollView: {
        backgroundColor: "#e7e7e7",
        flexGrow: 1
    },
    mainbody: {
        flex: 1,
        alignItems: 'center',
        paddingVertical: 20,
    },
    body: {
        width: '100%',
        paddingVertical: Units.isIOS? 10: 0,
        flex: 1,
    },
    flatCon: {
        alignSelf: "center",
        alignItems: "center",
        minWidth: 300,
        paddingHorizontal: 10,
    },
    fabBtn:{
        position: 'absolute',
        width: 50,
        height: 50,
        borderRadius: 25,
        alignItems: "center",
        justifyContent: "center",
        right: 50,
        display: "flex",
        backgroundColor: EDColors.gradient[0],
        shadowColor: "black",
        shadowOffset: {width: 1, height:1},
        shadowOpacity: .75,
        shadowRadius: 20,
        elevation: 3,
    },
    fabLeftBtn:{
        position: 'absolute',
        height: 50,
        borderRadius: 25,
        alignItems: "center",
        justifyContent: "center",
        left: 400,
        display: "flex",
        backgroundColor: EDColors.gradient[0],
        shadowColor: "black",
        shadowOffset: {width: 1, height:1},
        shadowOpacity: .75,
        shadowRadius: 20,
        elevation: 3,
        paddingHorizontal: 30
    },
    fabLeftBtnTxt: {
        fontFamily: Units.ftFamily.MontserratB,
        color: "#fff",
        fontSize: 16
    },
    floatingBtn: {
        resizeMode: "contain",
        width: 50,
        height: 50,
    },
    modalCon:{position: 'absolute', top:0, left:0, flex:1, justifyContent: 'center', alignItems:'center'},
    selectCon: {
        width: "100%"  ,
        flexDirection: "row",
        justifyContent: "space-between",
    },
    quelenTxt: {
        fontFamily: Units.ftFamily.MontserratR,
        color: EDColors.gradient[0],
        fontSize: Units.ftSizes.default,
    },
    selectSubCon: {
        flexDirection: "row",
        marginTop: 10,
    },
    subCon: {
        flex: 4.5,
    },
    itemCon: {
        flex : 1,
        padding: 20,
        alignItems: 'center',
        backgroundColor: EDColors.topbar,
        marginVertical: 20,
        width: '70%',
        minWidth: 300,
        maxWidth: 500,
        alignSelf: 'center',
        borderRadius: 25,
        shadowOffset: { width: 0, height: 2 },
        shadowColor: EDColors.topbar,
        shadowOpacity: .5,
        shadowRadius: 5,
        borderWidth: 1,
        borderColor: EDColors.gradient[0],
    },
    buttonCon: {
        flex: 1,
        width: "90%",
        minWidth: 260,
        marginTop: 20,
        // marginBottom: 10,
        justifyContent: "flex-end",
    },
    levelitemCon: {
        paddingBottom: 10,
        marginHorizontal: 15,
        justifyContent: "flex-end",
    },
    actLevelitemCon: {
        paddingBottom: 10,
        marginHorizontal: 15,
        borderBottomWidth: 2,
        justifyContent: "flex-end",
        borderBottomColor: EDColors.gradient[1],
    },
    markImg: {
        // marginTop: 10,
        width: 200,
        height: 200,
    },
    levelTxt: {
        fontFamily: Units.ftFamily.MontserratR,
        color: EDColors.gradient[2],
        fontSize: Units.ftSizes.big,
    },
    actlevelTxt: {
        fontFamily: Units.ftFamily.MontserratR,
        color: EDColors.gradient[0],
        fontSize: Units.ftSizes.big,
    },
    nameTxt: {
        fontFamily: Units.ftFamily.MontserratR,
        color: EDColors.white,
        fontSize: Units.ftSizes.extra,
        lineHeight: 45,
        textAlign: "center",
        paddingTop: Units.isTablet? 20 : 10,
    },
    nameTxt2: {
        fontFamily: Units.ftFamily.MontserratR,
        color: EDColors.gradient[2],
        fontSize: Units.ftSizes.medium,
        letterSpacing: 2,
        paddingTop: Units.isTablet? 20: 10,
    },
    delModalCon: {
        flex: 1,
        width: "100%",
        height: "100%",
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
    },
    waitModalCon: {
        flex: 1,
        backgroundColor: EDColors.topbar,
        opacity: .5,
        width: "100%",
        height: "100%",
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
    },
    otherModalCon:{
        flex: 1,
        width: Units.screenSize.W,
        height: Units.screenSize.H,
        paddingTop: "30%",
        position: "absolute",
        alignItems: "center",
        backgroundColor: EDColors.modalbk,
    },
    otherModalCon2: {
        // paddingTop: "30%",
        width: Units.screenSize.W,
        height: "100%",
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
    }
});

export default styles;