import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  ActivityIndicator,
  Dimensions,
  Pressable,
  FlatList,
} from "react-native";

import styles from "./style";
import { EDColors } from "../../constants/Colors";
import { ScrollView } from "react-native-gesture-handler";
import SideBar from "../../components/sidebar";
import Header from "../../components/Header";
import MainTab from "./mainTab";
import Quescomp from "./quescomp";
import FilterComp from "./filterComp";
import PracticeComp from "./practiceComp";
import {
  getPracticeTestResults,
  getQuestionsAnalyticsData,
  getQuestionsAnalyticsDataWithPagination,
  getQuestionsDatabyCustomLesson,
} from "../../actions/OfflineAction";
import Pagination from "./pagination";
import { Units } from "../../constants/Unit";
const _ = require("lodash");

const StudentInfo = ({ navigation }) => {
  const [selectedMainTab, setSelectedMainTab] = useState("Questions");
  const [questionsData, setQuestionsData] = useState(null);
  const [questionsNCVECLessonData, setQuestionsNCVECLessonData] =
    useState(null);
  const [questionsNCVECTopicData, setQuestionsNCVECTopicData] = useState(null);
  const [practiceTestResults, setPracticeTestResults] = useState([]);
  const [page, setPage] = useState(0);
  const [pageNCVECLesson, setPageNCVECLesson] = useState(0);
  const [pageNCVECTopic, setPageNCVECTopic] = useState(0);
  const [lastDocumentForQuestions, setLastDocumentForQuestions] =
    useState(null);
  const [firstDocumentForQuestions, setFirstDocumentForQuestions] =
    useState(null);
  const [
    lastDocumentForQuestionsNCVECLesson,
    setLastDocumentForQuestionsNCVECLesson,
  ] = useState(null);
  const [
    firstDocumentForQuestionsNCVECLesson,
    setFirstDocumentForQuestionsNCVECLesson,
  ] = useState(null);
  const [
    lastDocumentForQuestionsNCVECTopic,
    setLastDocumentForQuestionsNCVECTopic,
  ] = useState(null);
  const [
    firstDocumentForQuestionsNCVECTopic,
    setFirstDocumentForQuestionsNCVECTopic,
  ] = useState(null);
  const [isLoadingQues, setIsLoadingQues] = useState(true);
  const [isLoadingPractice, setIsLoadingPractice] = useState(true);
  const [customData, setCustomData] = useState();
  const [customTopicData, setCustomTopicData] = useState();
  const [NCVECLessonData, setNCVECLessonData] = useState();
  const [NCVECTopicData, setNCVECTopicData] = useState();
  const [isLoadingNCVECLesson, setIsLoadingNCVECLesson] = useState(true);
  const [isLoadingNCVECTopic, setIsLoadingNCVECTopic] = useState(true);
  const [isLoadingCustomLesson, setIsLoadingCustomLesson] = useState(false);
  const [isLoadingCustomTopic, setIsLoadingCustomTopic] = useState(false);
  const [selectedIndexLevel, setSelectedIndexLevel] = useState(-1);
  const [selectedIndexLesson, setSelectedIndexLesson] = useState(-1);
  const [selectedIndexTopic, setSelectedIndexTopic] = useState(-1);
  const [filterQuestionNo, setFilterQuestionNo] = useState("");
  const [selectedCustomLesson, setSelectedCustomLesson] = useState(-1);
  const [selectedCustomTopic, setSelectedCustomTopic] = useState(-1);
  const [selectedNCVECLesson, setSelectedNCVECLesson] = useState(-1);
  const [selectedNCVECTopic, setSelectedNCVECTopic] = useState(-1);
  let filter = {};
  let limit = 25;

  const [window, setHeight] = useState(Dimensions.get("window"));

  const onChange = ({ window, screen }) => {
    setHeight(window);
  };

  const handleLoading = (value) => {
    if (selectedMainTab === "Questions") {
      setIsLoadingQues(value);
    } else if (selectedMainTab === "NCVEC Lessons") {
      setIsLoadingNCVECLesson(value);
    } else if (selectedMainTab === "NCVEC Topics") {
      setIsLoadingNCVECTopic(value);
    }
  };

  const fetchQuestionAnaliticsData = async (limit, filter) => {
    handleLoading(true);
    try {
      const res = await getQuestionsAnalyticsData(limit, filter);
      if (res && res?.length > 0) {
        let lastObj = { ...res[res.length - 1] };
        delete lastObj.id;
        if (selectedMainTab === "Questions") {
          const resData = res.sort((a, b) => {
            let aavg = (a?.NumTimesCorrect * 100) / a?.NumTimesAttempted;
            let bavg = (b?.NumTimesCorrect * 100) / b?.NumTimesAttempted;
            return aavg - bavg;
          });
          setQuestionsData(resData);
          setLastDocumentForQuestions(res[res.length - 1]);
          setFirstDocumentForQuestions(res[0]);
        } else if (selectedMainTab === "NCVEC Lessons") {
          const resData = res.sort((a, b) => {
            let aavg = (a?.NumTimesCorrect * 100) / a?.NumTimesAttempted;
            let bavg = (b?.NumTimesCorrect * 100) / b?.NumTimesAttempted;
            return aavg - bavg;
          });
          setQuestionsNCVECLessonData(resData);
          setLastDocumentForQuestionsNCVECLesson(res[res.length - 1]);
          setFirstDocumentForQuestionsNCVECLesson(res[0]);
        } else if (selectedMainTab === "NCVEC Topics") {
          const resData = res.sort((a, b) => {
            let aavg = (a?.NumTimesCorrect * 100) / a?.NumTimesAttempted;
            let bavg = (b?.NumTimesCorrect * 100) / b?.NumTimesAttempted;
            return aavg - bavg;
          });
          setQuestionsNCVECTopicData(resData);
          setLastDocumentForQuestionsNCVECTopic(res[res.length - 1]);
          setFirstDocumentForQuestionsNCVECTopic(res[0]);
        }
      } else {
        if (selectedMainTab === "Questions") {
          setQuestionsData([]);
        } else if (selectedMainTab === "NCVEC Lessons") {
          setQuestionsNCVECLessonData([]);
        } else if (selectedMainTab === "NCVEC Topics") {
          setQuestionsNCVECTopicData([]);
        }
      }
    } catch (e) {
      if (selectedMainTab === "Questions") {
        setQuestionsData([]);
      } else if (selectedMainTab === "NCVEC Lessons") {
        setQuestionsNCVECLessonData([]);
      } else if (selectedMainTab === "NCVEC Topics") {
        setQuestionsNCVECTopicData([]);
      }
      console.log("QUESTION DATA: ", e);
    }
    handleLoading(false);
  };

  const getFirstDoc = () => {
    if (selectedMainTab === "Questions") {
      return firstDocumentForQuestions;
    } else if (selectedMainTab === "NCVEC Lessons") {
      return firstDocumentForQuestionsNCVECLesson;
    } else if (selectedMainTab === "NCVEC Topics") {
      return firstDocumentForQuestionsNCVECTopic;
    }
  };

  const getLastDoc = () => {
    if (selectedMainTab === "Questions") {
      return lastDocumentForQuestions;
    } else if (selectedMainTab === "NCVEC Lessons") {
      return lastDocumentForQuestionsNCVECLesson;
    } else if (selectedMainTab === "NCVEC Topics") {
      return lastDocumentForQuestionsNCVECTopic;
    }
  };

  const fetchQuestionAnaliticsDataWithPagination = async (
    limit,
    pagination
  ) => {
    handleLoading(true);
    try {
      const res = await getQuestionsAnalyticsDataWithPagination(
        limit,
        getLastDoc(),
        getFirstDoc(),
        filter,
        pagination
      );
      if (res && res?.length > 0) {
        if (selectedMainTab === "Questions") {
          if (pagination === -1 && page !== 0) {
            setPage((prev) => prev - 1);
          } else if (pagination === 1) {
            setPage((prev) => prev + 1);
          }
          setQuestionsData(res);
          setLastDocumentForQuestions(res[res.length - 1]);
          setFirstDocumentForQuestions(res[0]);
        } else if (selectedMainTab === "NCVEC Lessons") {
          if (pagination === -1 && pageNCVECLesson !== 0) {
            setPageNCVECLesson((prev) => prev - 1);
          } else if (pagination === 1) {
            setPageNCVECLesson((prev) => prev + 1);
          }
          setQuestionsNCVECLessonData(res);
          setLastDocumentForQuestionsNCVECLesson(res[res.length - 1]);
          setFirstDocumentForQuestionsNCVECLesson(res[0]);
        } else if (selectedMainTab === "NCVEC Topics") {
          if (pagination === -1 && pageNCVECTopic !== 0) {
            setPageNCVECTopic((prev) => prev - 1);
          } else if (pagination === 1) {
            setPageNCVECTopic((prev) => prev + 1);
          }
          setQuestionsNCVECTopicData(res);
          setLastDocumentForQuestionsNCVECTopic(res[res.length - 1]);
          setFirstDocumentForQuestionsNCVECTopic(res[0]);
        }
      } else {
        if (selectedMainTab === "Questions") {
          setQuestionsData([]);
        } else if (selectedMainTab === "NCVEC Lessons") {
          setQuestionsNCVECLessonData([]);
        } else if (selectedMainTab === "NCVEC Topics") {
          setQuestionsNCVECTopicData([]);
        }
      }
    } catch (e) {
      if (selectedMainTab === "Questions") {
        setQuestionsData([]);
      } else if (selectedMainTab === "NCVEC Lessons") {
        setQuestionsNCVECLessonData([]);
      } else if (selectedMainTab === "NCVEC Topics") {
        setQuestionsNCVECTopicData([]);
      }
      console.log("QUESTION DATA WITH PAGINATION: ", e);
    }
    handleLoading(false);
  };

  const fetchPracticeTestData = async () => {
    setIsLoadingPractice(true);
    try {
      const res = await getPracticeTestResults();
      console.log(res);
      setPracticeTestResults(res);
    } catch (e) {
      console.log("ERROR WHILE FETCHING PRACTICE DATA: ", e);
    }
    setIsLoadingPractice(false);
  };

  const handleClear = () => {
    filter = {};
    setPage(0);
    setPageNCVECLesson(0);
    setPageNCVECTopic(0);
    setFilterQuestionNo("");
    setSelectedIndexTopic(-1);
    setSelectedIndexLevel(-1);
    setSelectedIndexLesson(-1);
    fetchQuestionAnaliticsData(limit, {});
  };

  const handleGrouping = async (arr, groupedData) => {
    Object.keys(groupedData).forEach(function (key, index) {
      var value = groupedData[key];
      let obj = {};
      const length = value.length;
      value.forEach((item, index) => {
        if (index === 0) {
          obj = { ...item };
        } else {
          obj.NumTimesAnsA = obj.NumTimesAnsA + item.NumTimesAnsA;
          obj.NumTimesAnsB = obj.NumTimesAnsB + item.NumTimesAnsB;
          obj.NumTimesAnsC = obj.NumTimesAnsC + item.NumTimesAnsC;
          obj.NumTimesAnsD = obj.NumTimesAnsD + item.NumTimesAnsD;
          obj.NumTimesAttempted =
            obj.NumTimesAttempted + item.NumTimesAttempted;
          obj.NumTimesCorrect = obj.NumTimesCorrect + item.NumTimesCorrect;
          obj.NumTimesMissed = obj.NumTimesMissed + item.NumTimesMissed;
        }
        if (length == index + 1) {
          arr.push(obj);
          obj = {};
        }
      });
    });
    return arr;
  };

  const fetchQuestionAnaliticsDataForCustomTopic = async (
    selectedCustomLesson
  ) => {
    setIsLoadingCustomTopic(true);
    try {
      let arr = [];
      const res = await getQuestionsDatabyCustomLesson(selectedCustomLesson);
      if (res?.length > 0) {
        const groupedData = _.groupBy(res, "CustomTopicID");
        const finalArr = await handleGrouping(arr, groupedData);
        const resData = finalArr.sort((a, b) => {
          let aavg = (a?.NumTimesCorrect * 100) / a?.NumTimesAttempted;
          let bavg = (b?.NumTimesCorrect * 100) / b?.NumTimesAttempted;
          return aavg - bavg;
        });
        setCustomTopicData(resData);
      } else {
        setCustomTopicData([]);
      }
    } catch (e) {
      setCustomTopicData([]);
    }
    setIsLoadingCustomTopic(false);
  };

  const fetchQuestionAnaliticsDataForNCVECLesson = async (
    selectedCustomLesson
  ) => {
    setIsLoadingNCVECLesson(true);
    try {
      let arr = [];
      const res = await getQuestionsDatabyCustomLesson(selectedCustomLesson);
      if (res?.length > 0) {
        const groupedData = _.groupBy(res, "SectionID");
        const finalArr = await handleGrouping(arr, groupedData);
        const resData = finalArr.sort((a, b) => {
          let aavg = (a?.NumTimesCorrect * 100) / a?.NumTimesAttempted;
          let bavg = (b?.NumTimesCorrect * 100) / b?.NumTimesAttempted;
          return aavg - bavg;
        });
        setNCVECLessonData(resData);
      } else {
        setNCVECLessonData([]);
      }
    } catch (e) {
      setNCVECLessonData([]);
    }
    setIsLoadingNCVECLesson(false);
  };

  const fetchQuestionAnaliticsDataForNCVECTopic = async (
    selectedCustomLesson
  ) => {
    setIsLoadingNCVECTopic(true);
    try {
      let arr = [];
      const res = await getQuestionsDatabyCustomLesson(selectedCustomLesson);
      if (res?.length > 0) {
        const customIteratee = (item) => item.QNo.substring(0, 3);
        const groupedData = _.groupBy(res, customIteratee);
        const finalArr = await handleGrouping(arr, groupedData);
        const resData = finalArr.sort((a, b) => {
          let aavg = (a?.NumTimesCorrect * 100) / a?.NumTimesAttempted;
          let bavg = (b?.NumTimesCorrect * 100) / b?.NumTimesAttempted;
          return aavg - bavg;
        });
        setNCVECTopicData(resData);
      } else {
        setNCVECTopicData([]);
      }
    } catch (e) {
      setNCVECTopicData([]);
    }
    setIsLoadingNCVECTopic(false);
  };

  const fetchQuestionAnaliticsDataForCustom = async (selectedCustomLesson) => {
    setIsLoadingCustomLesson(true);
    try {
      let arr = [];
      const res = await getQuestionsDatabyCustomLesson(selectedCustomLesson);
      if (res?.length > 0) {
        const groupedData = _.groupBy(res, "CustomLessonID");
        const finalArr = await handleGrouping(arr, groupedData);
        const resData = finalArr.sort((a, b) => {
          let aavg = (a?.NumTimesCorrect * 100) / a?.NumTimesAttempted;
          let bavg = (b?.NumTimesCorrect * 100) / b?.NumTimesAttempted;
          return aavg - bavg;
        });
        setCustomData(resData);
      } else {
        setCustomData([]);
      }
    } catch (e) {
      setCustomData([]);
    }
    setIsLoadingCustomLesson(false);
  };

  useEffect(() => {
    if (selectedMainTab === "Questions" && isLoadingQues) {
      fetchQuestionAnaliticsData(limit, {});
    } else if (selectedMainTab === "Practice Tests" && isLoadingPractice) {
      fetchPracticeTestData();
    } else if (selectedMainTab === "NCVEC Topics" && isLoadingNCVECTopic) {
      fetchQuestionAnaliticsData(limit, {});
    }
  }, [selectedMainTab]);

  useEffect(() => {
    if (selectedCustomLesson != "-1") {
      fetchQuestionAnaliticsDataForCustom(parseInt(selectedCustomLesson) + 1);
    }
  }, [selectedCustomLesson]);

  useEffect(() => {
    if (selectedCustomTopic != "-1") {
      fetchQuestionAnaliticsDataForCustomTopic(
        parseInt(selectedCustomTopic) + 1
      );
    }
  }, [selectedCustomTopic]);

  useEffect(() => {
    if (selectedNCVECLesson != "-1") {
      fetchQuestionAnaliticsDataForNCVECLesson(
        parseInt(selectedNCVECLesson) + 1
      );
    }
  }, [selectedNCVECLesson]);

  useEffect(() => {
    if (selectedNCVECTopic != "-1") {
      fetchQuestionAnaliticsDataForNCVECTopic(parseInt(selectedNCVECTopic) + 1);
    }
  }, [selectedNCVECTopic]);

  useEffect(() => {
    Dimensions.addEventListener("change", onChange);
    return () => {
      Dimensions.removeEventListener("change", onChange);
    };
  }, []);

  return (
    <View
      style={[
        styles.container,
        { maxHeight: window.height, flexDirection: "row" },
      ]}
    >
      <SideBar navigation={navigation} />
      <>
        <ScrollView contentContainerStyle={styles.scrollView}>
          <Header />
          <Text style={styles.title}>Analyze Results</Text>
          <View style={styles.bg}>
            <View style={styles.tableContainer}>
              <MainTab
                setSelectedMainTab={setSelectedMainTab}
                selectedMainTab={selectedMainTab}
              />
              {selectedMainTab === "Questions" ? (
                <>
                  <FilterComp
                    selectLevel
                    custom
                    topics
                    ques
                    btn
                    fetchQuestionAnaliticsData={fetchQuestionAnaliticsData}
                    selectedIndexLevel={selectedIndexLevel}
                    setSelectedIndexLevel={setSelectedIndexLevel}
                    selectedIndexLesson={selectedIndexLesson}
                    setSelectedIndexLesson={setSelectedIndexLesson}
                    selectedIndexTopic={selectedIndexTopic}
                    setSelectedIndexTopic={setSelectedIndexTopic}
                    filterQuestionNo={filterQuestionNo}
                    setFilterQuestionNo={setFilterQuestionNo}
                    filter={filter}
                    limit={limit}
                  />
                  <Pressable
                    style={{
                      marginLeft: 27,
                      marginBottom: 20,
                      width: "fit-content",
                    }}
                    onPress={handleClear}
                  >
                    <Text style={{ color: "red" }}>Clear Filter</Text>
                  </Pressable>

                  <View style={styles.full}>
                    {isLoadingQues ? (
                      <View style={styles.loadingWrapper}>
                        <ActivityIndicator
                          size="large"
                          color={EDColors.gradient[1]}
                        />
                      </View>
                    ) : questionsData ? (
                      // questionsData.map((item, index) => {
                      //   let avg =
                      //     (item?.NumTimesCorrect * 100) /
                      //     item?.NumTimesAttempted;
                      //   return (
                      //     <Quescomp
                      //       key={index}
                      //       levelId={item?.LevelID}
                      //       Qno={item?.QNo}
                      //       fill={Math.trunc(avg)}
                      //       total={item?.NumTimesAttempted}
                      //       pass={item?.NumTimesCorrect}
                      //       failed={item?.NumTimesMissed}
                      //     />
                      //   );
                      // })
                      <FlatList
                        data={questionsData}
                        renderItem={({ item }) => {
                          let avg =
                            (item?.NumTimesCorrect * 100) /
                            item?.NumTimesAttempted;
                          return (
                            <Quescomp
                              levelId={item?.LevelID}
                              Qno={item?.QNo}
                              fill={Math.trunc(avg)}
                              total={item?.NumTimesAttempted}
                              pass={item?.NumTimesCorrect}
                              failed={item?.NumTimesMissed}
                            />
                          );
                        }}
                        keyExtractor={(item) => item.id}
                      />
                    ) : null}
                    {/* {questionsData ? (
                      <Pagination page={page} fetchQuestionAnaliticsDataWithPagination={fetchQuestionAnaliticsDataWithPagination} questionsDataTotal={questionsData?.length} limit={limit} />
                    ) : null} */}
                  </View>
                </>
              ) : selectedMainTab === "Practice Tests" ? (
                <View
                  style={{
                    alignItems: "center",
                    paddingTop: 30,
                  }}
                >
                  {practiceTestResults && !isLoadingPractice ? (
                    practiceTestResults.map((item, index) => {
                      let avg =
                        (item?.NumTimesPassed * 100) / item?.NumTimesAttempted;
                      return (
                        <PracticeComp
                          key={index}
                          avgScore={!avg ? 0 : Math.trunc(avg)}
                          passRate={30}
                          attempt={item?.NumTimesAttempted}
                          pass={item?.NumTimesPassed}
                          fail={item?.NumTimesFailed}
                          levelId={item?.LevelID}
                        />
                      );
                    })
                  ) : (
                    <View style={styles.loadingWrapper}>
                      <ActivityIndicator
                        size="large"
                        color={EDColors.gradient[1]}
                      />
                    </View>
                  )}
                </View>
              ) : selectedMainTab === "Custom Lessons" ? (
                <>
                  <FilterComp
                    selectLevel
                    // btn
                    fetchQuestionAnaliticsData={
                      fetchQuestionAnaliticsDataForCustom
                    }
                    selectedIndexLevel={selectedCustomLesson}
                    setSelectedIndexLevel={setSelectedCustomLesson}
                  />
                  {selectedCustomLesson == -1 ? (
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginVertical: 20,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 18,
                          fontFamily: Units.ftFamily.MontserratB,
                        }}
                      >
                        Please select a level to get data.
                      </Text>
                    </View>
                  ) : isLoadingCustomLesson ? (
                    <View style={styles.loadingWrapper}>
                      <ActivityIndicator
                        size="large"
                        color={EDColors.gradient[1]}
                      />
                    </View>
                  ) : customData && customData.length ? (
                    customData.map((item, index) => {
                      let avg =
                        (item?.NumTimesCorrect * 100) / item?.NumTimesAttempted;
                      return (
                        <Quescomp
                          customLessonID={item?.CustomLessonID}
                          byCustom
                          key={index}
                          levelId={item?.LevelID}
                          Qno={item?.QNo}
                          fill={Math.trunc(avg)}
                          total={item?.NumTimesAttempted}
                          pass={item?.NumTimesCorrect}
                          failed={item?.NumTimesMissed}
                        />
                      );
                    })
                  ) : (
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginVertical: 20,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 18,
                          fontFamily: Units.ftFamily.MontserratB,
                        }}
                      >
                        No data.
                      </Text>
                    </View>
                  )}
                </>
              ) : selectedMainTab === "Custom Topics" ? (
                <>
                  <FilterComp
                    selectLevel
                    // btn
                    fetchQuestionAnaliticsData={
                      fetchQuestionAnaliticsDataForCustomTopic
                    }
                    selectedIndexLevel={selectedCustomTopic}
                    setSelectedIndexLevel={setSelectedCustomTopic}
                  />
                  {selectedCustomTopic == -1 ? (
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginVertical: 20,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 18,
                          fontFamily: Units.ftFamily.MontserratB,
                        }}
                      >
                        Please select a level to get data.
                      </Text>
                    </View>
                  ) : isLoadingCustomTopic ? (
                    <View style={styles.loadingWrapper}>
                      <ActivityIndicator
                        size="large"
                        color={EDColors.gradient[1]}
                      />
                    </View>
                  ) : customTopicData && customTopicData.length ? (
                    customTopicData.map((item, index) => {
                      let avg =
                        (item?.NumTimesCorrect * 100) / item?.NumTimesAttempted;
                      return (
                        <Quescomp
                          byTopic
                          customLessonID={item?.CustomTopicID}
                          byCustom
                          key={index}
                          levelId={item?.LevelID}
                          Qno={item?.QNo}
                          fill={Math.trunc(avg)}
                          total={item?.NumTimesAttempted}
                          pass={item?.NumTimesCorrect}
                          failed={item?.NumTimesMissed}
                        />
                      );
                    })
                  ) : (
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginVertical: 20,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 18,
                          fontFamily: Units.ftFamily.MontserratB,
                        }}
                      >
                        No data.
                      </Text>
                    </View>
                  )}
                </>
              ) : selectedMainTab === "NCVEC Lessons" ? (
                <>
                  <FilterComp
                    selectLevel
                    // btn
                    fetchQuestionAnaliticsData={
                      fetchQuestionAnaliticsDataForNCVECLesson
                    }
                    selectedIndexLevel={selectedNCVECLesson}
                    setSelectedIndexLevel={setSelectedNCVECLesson}
                  />
                  {selectedNCVECLesson == -1 ? (
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginVertical: 20,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 18,
                          fontFamily: Units.ftFamily.MontserratB,
                        }}
                      >
                        Please select a level to get data.
                      </Text>
                    </View>
                  ) : isLoadingNCVECLesson ? (
                    <View style={styles.loadingWrapper}>
                      <ActivityIndicator
                        size="large"
                        color={EDColors.gradient[1]}
                      />
                    </View>
                  ) : NCVECLessonData && NCVECLessonData.length ? (
                    NCVECLessonData.map((item, index) => {
                      let avg =
                        (item?.NumTimesCorrect * 100) / item?.NumTimesAttempted;
                      return (
                        <Quescomp
                          NCVECLesson
                          SectionID={item?.SectionID}
                          key={index}
                          levelId={item?.LevelID}
                          Qno={item?.QNo}
                          fill={Math.trunc(avg)}
                          total={item?.NumTimesAttempted}
                          pass={item?.NumTimesCorrect}
                          failed={item?.NumTimesMissed}
                        />
                      );
                    })
                  ) : (
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginVertical: 20,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 18,
                          fontFamily: Units.ftFamily.MontserratB,
                        }}
                      >
                        No data.
                      </Text>
                    </View>
                  )}
                </>
              ) : selectedMainTab === "NCVEC Topics" ? (
                <>
                  <FilterComp
                    selectLevel
                    // btn
                    fetchQuestionAnaliticsData={
                      fetchQuestionAnaliticsDataForNCVECTopic
                    }
                    selectedIndexLevel={selectedNCVECTopic}
                    setSelectedIndexLevel={setSelectedNCVECTopic}
                  />
                  {selectedNCVECTopic == -1 ? (
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginVertical: 20,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 18,
                          fontFamily: Units.ftFamily.MontserratB,
                        }}
                      >
                        Please select a level to get data.
                      </Text>
                    </View>
                  ) : isLoadingNCVECTopic ? (
                    <View style={styles.loadingWrapper}>
                      <ActivityIndicator
                        size="large"
                        color={EDColors.gradient[1]}
                      />
                    </View>
                  ) : NCVECTopicData && NCVECTopicData.length ? (
                    NCVECTopicData.map((item, index) => {
                      let avg =
                        (item?.NumTimesCorrect * 100) / item?.NumTimesAttempted;
                      return (
                        <Quescomp
                          NCVECTopic
                          SectionID={item?.SectionID}
                          key={index}
                          levelId={item?.LevelID}
                          Qno={item?.QNo}
                          fill={Math.trunc(avg)}
                          total={item?.NumTimesAttempted}
                          pass={item?.NumTimesCorrect}
                          failed={item?.NumTimesMissed}
                        />
                      );
                    })
                  ) : (
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginVertical: 20,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 18,
                          fontFamily: Units.ftFamily.MontserratB,
                        }}
                      >
                        No data.
                      </Text>
                    </View>
                  )}
                </>
              ) : null}
            </View>
          </View>
        </ScrollView>
      </>
    </View>
  );
};

export default StudentInfo;
