import React from 'react';
import { useNavigation } from '@react-navigation/native';
import {
    View,
    TouchableOpacity,
    Text,
    StyleSheet,
} from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { EDColors } from '../constants/Colors';
import { Units } from '../constants/Unit';

const TopBar =({title, logout, onLogout, flag, onRightPress, isMobile, leave, subtitle})=> {
    const navigation = useNavigation();

    return (
        <View style={[styles.headerCon]}>
            <TouchableOpacity style={styles.leftCon} onPress = {()=> navigation.goBack()}>
                <MaterialCommunityIcons
                    name = {leave? "close" : "chevron-left"}
                    size = {24}
                    color = {EDColors.white}
                />
                {!isMobile&&<Text style={styles.backTxt}>{leave? "Leave Exam" :"Back"}</Text>}
            </TouchableOpacity>
            <View style={isMobile? {alignItems: "center"}:{alignItems: "center", marginLeft: -30}}>
                <Text style={[styles.title]}>{title}</Text>
                <Text style={styles.subtitle}>{subtitle}</Text>
            </View>
            {logout && <TouchableOpacity style = {styles.logout} onPress={() => onLogout()}>
                <MaterialCommunityIcons
                    name="logout"
                    color = {EDColors.primary}
                    size = {24}
                />
            </TouchableOpacity>}
            {flag ? <TouchableOpacity style = {styles.logout} onPress={() => onRightPress()}>
                <MaterialCommunityIcons
                    name={flag}
                    color = {EDColors.white}
                    size = {23}
                />
                </TouchableOpacity>:
            <View style={{width: 30}}></View>}
        </View>
    );
}

const styles = StyleSheet.create({
    headerCon:{
        width: '100%',
        backgroundColor: EDColors.topbar,
        paddingHorizontal: Units.isTablet? 30: 10,
        paddingTop: Units.isTablet? Units.statusH + 20: Units.statusH + 20,
        flexDirection: 'row',
        justifyContent:'space-between',
        alignItems: "center",
    },
    leftCon: {
        flexDirection:'row',
        alignItems: "center",
    },
    backTxt:{
        fontFamily: Units.ftFamily.KarlaR,
        color: EDColors.white,
        fontSize: Units.ftSizes.middefault,
        marginLeft: 5,
    },
    title:{
        fontFamily: Units.ftFamily.MontserratR,
        color: EDColors.white,
        fontSize: Units.ftSizes.medium,
        marginTop: 3,
    },
    subtitle: {
        fontFamily: Units.ftFamily.MontserratB,
        color: EDColors.gradient[2],
        fontSize: Units.ftSizes.big,
        marginTop: 5,
    },
    logout: {
        paddingHorizontal: 10,
    }
})
export default TopBar;