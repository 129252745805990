import React from 'react';
import { StyleSheet, View } from 'react-native';

import Modal from 'react-native-modal';
import QuesBlock from './quesBlock';

const emptyItem = {
    AnswerA: "",
    AnswerB: "",
    AnswerC: "",
    AnswerD: "",
    Correctanswer: "",
    Help: "",
    IsImageQuestion: "",
    LevelId: -1,
    QNo: "",
    Question: "",
    Section: "",
    SectionId: "",
    id: "",
    imageName: "",
}

const AddModal = ({ modalVisible, onClose, width, sWidth, levelData, isMobile, sectionData, sHeight, addNewItem, quesData }) => {
    return (
        <Modal
            testID={'modal'}
            isVisible={modalVisible}
            onBackdropPress={onClose}
            onSwipeComplete={onClose}
            deviceWidth={sWidth}
            deviceHeight={sHeight}
            swipeDirection={['down']}
            hideModalContentWhileAnimating={true}
            style={[styles.view, { width: width }]}
        >
            <View style={[styles.container, { height: sHeight - 100 }]}>
                <QuesBlock
                    item={emptyItem}
                    quesData={quesData}
                    lData={levelData}
                    sData={sectionData}
                    isMobile={isMobile}
                    isNew
                    sHeight={sHeight}
                    onClose={onClose}
                    handleAddNewItem={(_) => addNewItem(_)}
                />
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({
    view: {
        flex: 1,
        justifyContent: 'center',
        margin: 0,
        alignSelf: "center",
    },
    container: {
        borderRadius: 10,
        alignItems: "center",
    },
});

export default AddModal;