import { StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import QuesDropDown from './quesDropDown'
import QuesInput from './quesInput'
import { GradientButton3 } from '../../components';

const IsImageData = [
    {
        Name: "Y",
    }, {
        Name: "N"
    }];
const ImageData = [{
    Name: "T1"
}, {
    Name: "T2"
}, {
    Name: "T3"
},
{
    Name: "E501"
},
{
    Name: "E601"
}, {
    Name: "E602"
}, {
    Name: "E603"
}, {
    Name: "E701"
}, {
    Name: "E702"
}, {
    Name: "E703"
}, {
    Name: "E901"
}, {
    Name: "E902"
}, {
    Name: "E903"
}, {
    Name: "General_Diagram"
},
];

const crtData = [{
    Name: "A"
}, {
    Name: "B"
}, {
    Name: "C"
}, {
    Name: "D"
}]

const QuesData = ({ item, quesData, lData, sData, isMobile, isNew, onClose, sHeight, handleAddNewItem, deleteItem, updateItem, setChangedItems, changedItems }) => {
    const [curLIdx, setCurLIdx] = useState(item.LevelId === -1 ? -1 : item.LevelId - 4);
    const [curSIdx, setCurSIdx] = useState(-1);
    const [curIsImageIdx, setCurIsImageIdx] = useState(-1);
    const [curImageIdx, setCurImageIdx] = useState(-1);
    const [curCrtIdx, setCurCrtIdx] = useState(-1);

    const [qNo, setQuesNo] = useState(item.QNo);
    const [question, setQuestion] = useState(item.Question);
    const [customLessonID, setCustomLessonID] = useState(item?.CustomLessonID ? item?.CustomLessonID : null);
    const [customTopicID, setCustomTopicID] = useState(item?.CustomTopicID ? item?.CustomTopicID : null);
    const [ansA, setAnsA] = useState(item.AnswerA);
    const [ansB, setAnsB] = useState(item.AnswerB);
    const [ansC, setAnsC] = useState(item.AnswerC);
    const [ansD, setAnsD] = useState(item.AnswerD);
    const [help, setHelp] = useState(item.Help);
    const [itemChanged, setItemChanged] = useState(false);
    const [isEdited, setIsEdited] = useState(false);

    const [qNoExist, setQNoExist] = useState(false);
    const [bImageName, setBImageName] = useState(false);


    useEffect(() => {
        console.log("changedItems.length: ", changedItems.length)
        if (changedItems.length === 0) {
            setIsEdited(false)
        } else {
            setIsEdited(true)
        }
    }, [changedItems])

    console.log("changedItems: ", changedItems)

    const multiUpdateHandler = () => {
        const lId = parseInt(curLIdx);
        const sId = parseInt(curSIdx);
        const newItem = {
            id: item?.id,
            AnswerA: ansA,
            AnswerB: ansB,
            AnswerC: ansC,
            AnswerD: ansD,
            CustomLessonID: customLessonID ? customLessonID : "",
            CustomTopicID: customTopicID ? customTopicID : "",
            Correctanswer: crtData[parseInt(curCrtIdx)]?.Name ? crtData[parseInt(curCrtIdx)]?.Name : "",
            Help: help ? help : "",
            IsImageQuestion: curIsImageIdx === -1 ? "N" : IsImageData[parseInt(curIsImageIdx)]?.Name,
            LevelId: lId === -1 ? 1 : parseInt(lId) + 4,
            QNo: qNo ? qNo : "",
            Question: question ? question : "",
            Section: sData[lId === -1 ? 0 : lId][parseInt(sId)]?.SectionId ? sData[lId === -1 ? 0 : lId][parseInt(sId)]?.SectionId : "",
            SectionId: sData[lId === -1 ? 0 : lId][parseInt(sId)]?.SectionId ? sData[lId === -1 ? 0 : lId][parseInt(sId)]?.SectionId : "",
            imageName: parseInt(curIsImageIdx) === 0 && parseInt(curImageIdx) !== -1 ? ImageData[parseInt(curImageIdx)]?.Name + ".png" : ""
        }
        setChangedItems((prev) => {
            let isAdded = false
            const length = prev.length
            for (var i = 0; i < length; i++) {
                if (prev[i]?.QNo === newItem?.QNo) {
                    isAdded = true;
                }
            }
            if (!isAdded) {
                const value = [...prev, newItem]
                console.log("VALUE: ", value)
                return [...prev, newItem]
            } else {
                const res = prev.filter((item) => {
                    return item.QNo !== newItem?.QNo
                })
                const value = [...res, newItem]
                console.log("VALUE TWO: ", value)
                return [...res, newItem]
            }
        })
        setItemChanged(false)
    }

    useEffect(() => {
        if (itemChanged) {
            multiUpdateHandler()
        }
    }, [itemChanged])

    useEffect(() => {

        if (item.SectionId !== "") {
            sData[curLIdx].map((_, _i) => {
                if (_i < 10) {
                    if (_.SectionId === item.SectionId) {
                        setCurSIdx(_i);
                    }
                }
            });
            IsImageData.map((_, _i) => {
                if (_.Name === item.IsImageQuestion) {
                    setCurIsImageIdx(_i);
                }
            });
            ImageData.map((_, _i) => {
                if (item.imageName !== undefined) {
                    if (_.Name === item.imageName.split('.')[0]) {
                        setCurImageIdx(_i);
                    }
                }
            });
            crtData.map((_, _i) => {
                if (_.Name === item.Correctanswer || _.Name === item.CorrectAnswer) {
                    setCurCrtIdx(_i);
                }
            });
        }
    }, []);

    const handleAddOrUpdate = () => {
        if (!validateAllItems()) {
            return;
        }
        const lId = parseInt(curLIdx);
        const sId = parseInt(curSIdx);
        const newItem = {
            AnswerA: ansA,
            AnswerB: ansB,
            AnswerC: ansC,
            AnswerD: ansD,
            CustomLessonID: customLessonID ? customLessonID : "",
            CustomTopicID: customTopicID ? customTopicID : "",
            Correctanswer: crtData[parseInt(curCrtIdx)]?.Name ? crtData[parseInt(curCrtIdx)]?.Name : "",
            Help: help ? help : "",
            IsImageQuestion: curIsImageIdx === -1 ? "N" : IsImageData[parseInt(curIsImageIdx)]?.Name,
            LevelId: lId === -1 ? 1 : parseInt(lId) + 4,
            QNo: qNo ? qNo : "",
            Question: question ? question : "",
            Section: sData[lId === -1 ? 0 : lId][parseInt(sId)]?.SectionId ? sData[lId === -1 ? 0 : lId][parseInt(sId)]?.SectionId : "",
            SectionId: sData[lId === -1 ? 0 : lId][parseInt(sId)]?.SectionId ? sData[lId === -1 ? 0 : lId][parseInt(sId)]?.SectionId : "",
            imageName: parseInt(curIsImageIdx) === 0 && parseInt(curImageIdx) !== -1 ? ImageData[parseInt(curImageIdx)]?.Name + ".png" : "",
        }
        isNew ? handleAddNewItem(newItem) : updateItem(item.id, newItem);
    }


    const validateAllItems = () => {

        if (curLIdx === -1) {
            return false;
        }
        if (qNo === "") { return false; }
        if (question === "") { return false; }
        if (ansA === "") { return false; }
        if (ansB === "") { return false; }
        if (ansC === "") { return false; }
        if (ansD === "") { return false; }
        if (curCrtIdx === -1) { return false; }
        // if( help === "" ){ return false; }
        if (curSIdx === -1) { return false; }
        if (curIsImageIdx === -1) { return false; }
        if (curIsImageIdx === 0 && curImageIdx === -1) {
            setBImageName(true);
            return false;
        }

        if (isNew) {
            const temp = quesData.filter((_) => _.QNo === qNo);
            if (temp && temp.length > 0) {
                setQNoExist(true);
                return false;
            }
        }

        return true;
    }

    return (
        <View style={{
            backgroundColor: isEdited ? "#4682B4" : "#c7c7c7",
            borderRadius: 30,
            padding: 25,
            marginHorizontal: 20,
            marginBottom: 40,
        }}>
            <QuesDropDown
                style={{
                    marginBottom: 10,
                }}
                dropDownStyle={{
                    paddingVertical: 15,
                }}
                blank
                title="Level: "
                multiline
                data={lData}
                // section="TRR"
                handlePress={(_h) => {
                    setCurLIdx(parseInt(_h))

                    setItemChanged(true)
                    setIsEdited(true)
                }}
                curIndex={curLIdx}
            />
            <QuesInput
                style={{
                    marginBottom: 10,
                    // height: 88
                }}
                textInputStyle={{
                    paddingVertical: 13,
                }}
                title="Question Number: "
                placeholder={"Input question number..."}
                multiline={false}
                value={qNo}
                onChangeText={(txt) => {
                    setQuesNo(txt);
                    if (qNoExist) {
                        setQNoExist(false);
                    }
                    setItemChanged(true)
                    setIsEdited(true)
                }}
            />
            {qNoExist && isNew && <View style={styles.validateCon}>
                {!isMobile && <View style={{ minWidth: 140 }}></View>}
                <Text style={styles.valTxt}>This number already exist!</Text>
            </View>}
            <QuesInput
                style={{
                    marginBottom: 10,
                    height: 88
                }}
                textInputStyle={{
                    paddingVertical: 15,
                }}
                title="Question: "
                placeholder={"Input question..."}
                multiline
                value={question}
                onChangeText={(txt) => {
                    setItemChanged(true)
                    setQuestion(txt)
                    setIsEdited(true)
                }}
            />
            <QuesInput
                style={{
                    marginBottom: 10,
                    // height: 88
                }}
                textInputStyle={{
                    paddingVertical: 15,
                }}
                title="Lesson ID: "
                placeholder={"Input lesson id..."}
                multiline={false}
                value={customLessonID}
                onChangeText={(txt) => {
                    setCustomLessonID(txt)
                    setItemChanged(true)
                    setIsEdited(true)
                }}
            />
            <QuesInput
                style={{
                    marginBottom: 10,
                    // height: 88
                }}
                textInputStyle={{
                    paddingVertical: 15,
                }}
                title="Topic ID: "
                placeholder={"Input topic id..."}
                multiline={false}
                value={customTopicID}
                onChangeText={(txt) => {
                    setItemChanged(true)
                    setIsEdited(true)
                    setCustomTopicID(txt)
                }}
            />
            <QuesInput
                style={{
                    marginBottom: 10,
                    height: 88
                }}
                textInputStyle={{
                    paddingVertical: 15,
                }}
                title="Answer A: "
                placeholder={"Input answer..."}
                multiline
                value={ansA}
                onChangeText={(txt) => {
                    setItemChanged(true)
                    setAnsA(txt)
                    setIsEdited(true)
                }}
            />
            <QuesInput
                style={{
                    marginBottom: 10,
                    height: 88
                }}
                textInputStyle={{
                    paddingVertical: 15,
                }}
                title="Answer B: "
                placeholder={"Input answer..."}
                multiline
                value={ansB}
                onChangeText={(txt) => {
                    setItemChanged(true)
                    setAnsB(txt)
                    setIsEdited(true)
                }}
            />
            <QuesInput
                style={{
                    marginBottom: 10,
                    height: 88
                }}
                textInputStyle={{
                    paddingVertical: 15,
                }}
                title="Answer C: "
                placeholder={"Input answer..."}
                multiline
                value={ansC}
                onChangeText={(txt) => {
                    setAnsC(txt)
                    setItemChanged(true)
                    setIsEdited(true)
                }}
            />
            <QuesInput
                style={{
                    height: 88,
                    marginBottom: 10,
                }}
                textInputStyle={{
                    paddingVertical: 15,
                }}
                title="Answer D: "
                placeholder={"Input answer..."}
                multiline
                value={ansD}
                onChangeText={(txt) => {
                    setItemChanged(true)
                    setAnsD(txt)
                    setIsEdited(true)
                }}
            />
            <QuesDropDown
                style={{
                    marginBottom: 10,
                }}
                dropDownStyle={{
                    paddingVertical: 15,
                }}
                blank
                title="Correct Answer: "
                multiline
                data={crtData}
                handlePress={(_h) => {
                    setCurCrtIdx(parseInt(_h))
                    setItemChanged(true)
                    setIsEdited(true)
                }}
                curIndex={curCrtIdx}
            />
            <QuesInput
                style={{
                    height: 88,
                    marginBottom: 10,
                }}
                textInputStyle={{
                    paddingVertical: 15,
                }}
                title="Explanation: "
                placeholder={"Input explanation..."}
                multiline
                value={help}
                onChangeText={(txt) => {
                    setItemChanged(true)
                    setHelp(txt)
                    setIsEdited(true)
                }}
                blank
            />
            <QuesDropDown
                style={{
                    marginBottom: 10,
                }}
                dropDownStyle={{
                    paddingVertical: 15,
                }}
                title="Section: "
                multiline
                data={curLIdx === -1 ? sData[0] : sData[curLIdx]}
                handlePress={(_h) => {
                    setCurSIdx(parseInt(_h))
                    setItemChanged(true)
                    setIsEdited(true)
                }}
                curIndex={curSIdx}
                blank
            />
            <QuesDropDown
                style={{
                    marginBottom: 10,
                }}
                dropDownStyle={{
                    paddingVertical: 15,
                }}
                title="is Image Question: "
                multiline
                data={IsImageData}
                handlePress={(_h) => {
                    setCurIsImageIdx(parseInt(_h))
                    setItemChanged(true)
                    setIsEdited(true)
                }}
                curIndex={curIsImageIdx}
            />
            <QuesDropDown
                dropDownStyle={{
                    paddingVertical: 15,
                }}
                title="Image Name: "
                multiline
                data={ImageData}
                handlePress={(_h) => {
                    setCurImageIdx(parseInt(_h));
                    if (bImageName) {
                        setBImageName(false);
                    }
                    setItemChanged(true)
                    setIsEdited(true)
                }}
                curIndex={curImageIdx}
            />
            <View style={{ flexDirection: "row", justifyContent: "space-evenly", marginTop: 40 }}>
                <GradientButton3
                    text={isNew ? "Add" : "update"}
                    handlePress={() => handleAddOrUpdate()}
                />
                <GradientButton3
                    text={isNew ? "cancel" : "delete"}
                    handlePress={() => {
                        setQNoExist(false);
                        isNew ? onClose() : deleteItem(item.id);
                    }}
                />
            </View>
        </View>
    )
}

export default QuesData

const styles = StyleSheet.create({})