import { Pressable, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { Units } from '../../constants/Unit'

const MainTab = ({
    setSelectedMainTab,
    selectedMainTab
}) => {
    return (
        <>
            <View style={styles.container}>
                {[
                "Questions",
                "Practice Tests",
                "Custom Lessons",
                "Custom Topics",
                "NCVEC Lessons",
                "NCVEC Topics",
                ].map((item, index)=>{
                return (
                    <Pressable style={[styles.tab, {backgroundColor: selectedMainTab === item ? "#0243DD" : "#fff"}]} onPress={()=>{
                        setSelectedMainTab(item);
                    }} key={index}>
                        <Text style={[styles.tabTxt, {color: selectedMainTab === item ? "#fff" : "#000"}]}>{item}</Text>
                    </Pressable>
                )})}
            </View>
            <View style={styles.divider} />
        </>
    )
}

export default MainTab

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        paddingVertical: 14,
        paddingHorizontal: 17
    },
    tab: {
        width: "16.66%",
        paddingVertical: 10,
        borderRadius: 11
    },
    tabTxt: {
        textAlign: "center",
        fontFamily: Units.ftFamily.MontserratB,
        fontSize: 15
    },
    divider: {
        backgroundColor: "#ccc",
        height: 1,
        width: "95%",
        marginHorizontal: "auto"
    }
})