import firebase from "firebase/app";
import "firebase/firestore";

import { tableNames } from "../constants/Config";

import { Units } from "../constants/Unit";
import { storeData } from "./General";

import { storageKey } from "../constants/StorageKey";
import { t } from "./Type";
import { toast } from "react-toastify";

// ====================  Start getting level data  ======================

export function updateBulkQues(arr) {
  return new Promise(async (resolve, reject) => {
    const batch = firebase.firestore().batch();
    try {
      arr.map(async (item) => {
        const { id, ...restOfItems } = item;
        // console.log("first==", {...restOfItems}, id)
        const docRef = firebase
          .firestore()
          .collection(`${tableNames.question}`)
          .doc(id);
        batch.update(docRef, { ...restOfItems });
      });
      const res = await batch.commit();
      resolve();
    } catch (e) {
      console.log("ERROR WHILE UPDATING BULK QUESTIONS", e);
      reject(e);
    }
  });
}

export function getQuestionsAnalyticsData(limit, filter) {
  return new Promise((resolve, reject) => {
    try {
      let isFilterWithQNo = "QNo";
      let query = firebase
        .firestore()
        .collection(`${tableNames.aggregateQuestionData}`);
      if (filter?.LevelId && filter?.LevelId != -1) {
        query = query.where("LevelID", "==", filter.LevelId?.LevelId);
      }
      if (filter?.CustomLessonID && filter?.CustomLessonID != -1) {
        query = query.where("CustomLessonID", "==", filter.CustomLessonID);
      }
      if (filter?.CustomTopicID && filter?.CustomTopicID != -1) {
        query = query.where("CustomTopicID", "==", filter.CustomTopicID);
      }
      if (filter?.filterQuestionNo && filter?.filterQuestionNo?.length) {
        query = query.where("QNo", "==", filter.filterQuestionNo);
        isFilterWithQNo = "SectionID";
      }
      query = query.orderBy(isFilterWithQNo);
      // query = query.limit(limit);
      query
        .get()
        .then((querySnapshot) => {
          let level = [];
          let count = 0;
          if (querySnapshot !== null) {
            querySnapshot.forEach(async (documentSnapshot) => {
              count++;
              level.push({
                id: documentSnapshot.id,
                ...documentSnapshot.data(),
              });

              // if( count === querySnapshot.size ){
              //     await storeData(storageKey.LEVEL_DATA, level);
              //     resolve(level);
              // }
            });
          }
          resolve(level);
        })
        .catch((error) => {
          console.log("ERROR CATCH : ", error);

          reject(error.code);
        });
    } catch (err) {
      console.log("ERROR HERE : ", err);
      reject(err);
    }
  });
}

export function getQuestionsDatabyCustomLesson(levelId) {
  return new Promise((resolve, reject) => {
    try {
      let query = firebase
        .firestore()
        .collection(`${tableNames.aggregateQuestionData}`);
      if (levelId) {
        query = query.where("LevelID", "==", levelId);
      }
      query
        .get()
        .then((querySnapshot) => {
          let level = [];
          let count = 0;
          if (querySnapshot !== null) {
            querySnapshot.forEach(async (documentSnapshot) => {
              count++;
              level.push({
                id: documentSnapshot.id,
                ...documentSnapshot.data(),
              });

              // if( count === querySnapshot.size ){
              //     await storeData(storageKey.LEVEL_DATA, level);
              //     resolve(level);
              // }
            });
          }
          resolve(level);
        })
        .catch((error) => {
          console.log("ERROR CATCH : ", error);

          reject(error.code);
        });
    } catch (err) {
      console.log("ERROR HERE : ", err);
      reject(err);
    }
  });
}

export function getQuestionsAnalyticsDataWithPagination(
  limit,
  lastVisible,
  firstVisible,
  filter,
  pagination
) {
  return new Promise((resolve, reject) => {
    try {
      let isFilterWithQNo = "QNo";
      let query = firebase
        .firestore()
        .collection(`${tableNames.aggregateQuestionData}`);
      if (filter?.LevelId && filter?.LevelId != -1) {
        query = query.where("LevelID", "==", filter.LevelId?.LevelId);
      }
      if (filter?.CustomLessonID && filter?.CustomLessonID != -1) {
        query = query.where("CustomLessonID", "==", filter.CustomLessonID);
      }
      if (filter?.CustomTopicID && filter?.CustomTopicID != -1) {
        query = query.where("CustomTopicID", "==", filter.CustomTopicID);
      }
      if (filter?.filterQuestionNo && filter?.filterQuestionNo?.length) {
        query = query.where("QNo", "==", filter.filterQuestionNo);
        isFilterWithQNo = "SectionID";
      }
      console.log("===========-----=", filter);
      query = query.orderBy(isFilterWithQNo);
      if (pagination === 1) {
        console.log("======1");
        query = query.startAfter(lastVisible[isFilterWithQNo]);
        query = query.limit(limit);
      } else {
        console.log("======-1");
        query = query.endBefore(firstVisible[isFilterWithQNo]);
        query = query.limitToLast(limit);
      }

      query
        .get()
        .then((querySnapshot) => {
          let level = [];
          let count = 0;
          if (querySnapshot !== null) {
            querySnapshot.forEach(async (documentSnapshot) => {
              count++;
              level.push({
                id: documentSnapshot.id,
                ...documentSnapshot.data(),
              });

              // if( count === querySnapshot.size ){
              //     await storeData(storageKey.LEVEL_DATA, level);
              //     resolve(level);
              // }
            });
          }
          resolve(level);
        })
        .catch((error) => {
          reject(error.code);
        });
    } catch (err) {
      reject(err);
    }
  });
}

export function getLevel() {
  return new Promise((resolve, reject) => {
    try {
      firebase
        .firestore()
        .collection(`${tableNames.level}`)
        .orderBy("LevelId", "asc")
        .get()
        .then((querySnapshot) => {
          let level = [];
          let count = 0;
          if (querySnapshot !== null) {
            querySnapshot.forEach(async (documentSnapshot) => {
              count++;
              level.push({
                id: documentSnapshot.id,
                ...documentSnapshot.data(),
              });
              if (count === querySnapshot.size) {
                await storeData(storageKey.LEVEL_DATA, level);
                resolve(level);
              }
            });
          }
        })
        .catch((error) => {
          reject(error.code);
        });
    } catch (err) {
      reject(err);
    }
  });
}

export async function updateEnrollment(data) {
  try {
    console.log(data);
    await fetch("https://hooks.zapier.com/hooks/catch/3401702/bjzxmtu/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
      body: JSON.stringify(data),
    });
  } catch (err) {
    console.log("ERROR WHILE UPDATING USER ENROLLMENT: ", err);
  }
}

export async function enrollmentHandler(uid, model) {
  try {
    return firebase
      .firestore()
      .collection(`${tableNames.enrollment}`)
      .doc(uid)
      .set(model, { merge: true });
  } catch (err) {
    console.log("SAVING USER ENROLLMENT ERROR: ", err);
  }
}

// export const listAllUsers = (nextPageToken) => {
//     // List batch of users, 1000 at a time.
//     getAuth()
//     .listUsers(1000, nextPageToken)
//     .then((listUsersResult) => {
//         listUsersResult.users.forEach((userRecord) => {
//             console.log('user', userRecord.toJSON());
//         });
//         if (listUsersResult.pageToken) {
//             // List next batch of users.
//             listAllUsers(listUsersResult.pageToken);
//         }
//     })
//     .catch((error) => {
//         console.log('Error listing users:', error);
//     });
// };

// start getting question data by level id

export const getUserEnrollmentInfo = (userId) => {
  return new Promise((resolve, reject) => {
    try {
      firebase
        .firestore()
        .collection(`${tableNames.enrollment}`)
        .doc(`${userId}`)
        .get()
        .then((doc) => {
          if (doc.exists) {
            resolve(doc.data());
          } else {
            resolve(null);
          }
        })
        .catch((e) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
};

export const getUserExamResultInfo = (userId, lid) => {
  return new Promise((resolve, reject) => {
    try {
      firebase
        .firestore()
        .collection(`${tableNames.practiceForRFU}`)
        .where("Userid", "==", userId)
        .where("LevelId", "==", lid)
        .orderBy("timeStamp", "desc")
        .limit(3)
        .get()
        .then((querySnapshot) => {
          let data = [];
          if (querySnapshot !== null) {
            querySnapshot.forEach(async (documentSnapshot) => {
              const oneitem = documentSnapshot.data();
              data.push({
                id: documentSnapshot.id,
                ...oneitem,
              });
            });

            resolve(data);
          }
        })
        .catch((error) => {
          reject(error.code);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export const getQuesDataForPracticeByLevel = (selectedLevel) => {
  return new Promise((resolve, reject) => {
    if (selectedLevel === undefined || selectedLevel === null) {
      resolve([]);
    }
    firebase
      .firestore()
      .collection(`${tableNames.question}`)
      .where("LevelId", "==", selectedLevel.LevelId)
      .get()
      .then(async (querySnapshot) => {
        let ques = [];
        let count = 0;
        if (querySnapshot !== null) {
          querySnapshot.forEach(async (documentSnapshot) => {
            const quesitem = documentSnapshot.data();
            ques.push({
              id: documentSnapshot.id,
              ...quesitem,
              answers: Units.alpha,
            });
            count++;
            if (count === querySnapshot.size) {
              resolve(ques);
            }
          });
          if (querySnapshot.size === 0) reject([]);
        } else {
          reject(null);
        }
      });
  });
};

export const getSectionData = (id) => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection(tableNames.section)
      .where("LevelId", "==", id)
      .get()
      .then((querySnapshot) => {
        let section = [];
        let count = 0;
        if (querySnapshot !== null) {
          querySnapshot.forEach(async function (documentSnapshot) {
            const item = documentSnapshot.data();
            section.push({
              id: documentSnapshot.id,
              ...item,
            });
            count++;
            if (count === querySnapshot.size) {
              const sortedData = section.sort(function (a, b) {
                return a.SectionNumber - b.SectionNumber;
              });
              resolve(sortedData);
            }
          });
          if (querySnapshot.size === 0) {
            resolve([]);
          }
        } else {
          reject(null);
        }
      });
  });
};

// export const getPracticeTestResults = () =>{
//     return new Promise((resolve, reject) => {
//         firebase.firestore().collection(tableNames.aggregatePracticeTestResults).get().then(querySnapshot => {
//             let section = [];
//             let count = 0;
//             if( querySnapshot !== null ){
//                 querySnapshot.forEach(async function( documentSnapshot) {
//                     const item = documentSnapshot.data();
//                     if(item?.LevelID) {
//                         section.push({
//                             id: documentSnapshot.id,
//                             ...item,
//                         });
//                     }
//                     count++;
//                     if( count === querySnapshot.size ){
//                         resolve(section);
//                     }
//                 });
//                 if( querySnapshot.size === 0 ){
//                     resolve([]);
//                 }
//             } else {
//                 reject(null);
//             }
//         });
//     });
// }

export const getPracticeTestResults = () => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection(tableNames.aggregatePracticeTestResults)
      .get()
      .then((querySnapshot) => {
        let level1 = {
          TotalQuestionsAttempted: 0,
          TotalNumQuestionsMissed: 0,
          TotalNumQuestionsCorrect: 0,
          NumTimesAttempted: 0,
          NumTimesFailed: 0,
          NumTimesPassed: 0,
          LevelID: 1,
        };
        let level2 = {
          TotalQuestionsAttempted: 0,
          TotalNumQuestionsMissed: 0,
          TotalNumQuestionsCorrect: 0,
          NumTimesAttempted: 0,
          NumTimesFailed: 0,
          NumTimesPassed: 0,
          LevelID: 2,
        };
        let level3 = {
          TotalQuestionsAttempted: 0,
          TotalNumQuestionsMissed: 0,
          TotalNumQuestionsCorrect: 0,
          NumTimesAttempted: 0,
          NumTimesFailed: 0,
          NumTimesPassed: 0,
          LevelID: 3,
        };
        let level4 = {
          TotalQuestionsAttempted: 0,
          TotalNumQuestionsMissed: 0,
          TotalNumQuestionsCorrect: 0,
          NumTimesAttempted: 0,
          NumTimesFailed: 0,
          NumTimesPassed: 0,
          LevelID: 4,
        };
        let level5 = {
          TotalQuestionsAttempted: 0,
          TotalNumQuestionsMissed: 0,
          TotalNumQuestionsCorrect: 0,
          NumTimesAttempted: 0,
          NumTimesFailed: 0,
          NumTimesPassed: 0,
          LevelID: 5,
        };
        let count = 0;
        if (querySnapshot !== null) {
          querySnapshot.forEach(async function (documentSnapshot) {
            const item = documentSnapshot.data();
            if (item?.LevelID == 1) {
              level1.TotalQuestionsAttempted =
                level1?.TotalQuestionsAttempted + item?.TotalQuestionsAttempted;
              level1.TotalNumQuestionsMissed =
                level1?.TotalNumQuestionsMissed + item?.TotalNumQuestionsMissed;
              level1.TotalNumQuestionsCorrect =
                level1?.TotalNumQuestionsCorrect +
                item?.TotalNumQuestionsCorrect;
              level1.NumTimesAttempted =
                level1?.NumTimesAttempted + item?.NumTimesAttempted;
              level1.NumTimesFailed =
                level1?.NumTimesFailed + item?.NumTimesFailed;
              level1.NumTimesPassed =
                level1?.NumTimesPassed + item?.NumTimesPassed;
            } else if (item?.LevelID == 2) {
              level2.TotalQuestionsAttempted =
                level2?.TotalQuestionsAttempted + item?.TotalQuestionsAttempted;
              level2.TotalNumQuestionsMissed =
                level2?.TotalNumQuestionsMissed + item?.TotalNumQuestionsMissed;
              level2.TotalNumQuestionsCorrect =
                level2?.TotalNumQuestionsCorrect +
                item?.TotalNumQuestionsCorrect;
              level2.NumTimesAttempted =
                level2?.NumTimesAttempted + item?.NumTimesAttempted;
              level2.NumTimesFailed =
                level2?.NumTimesFailed + item?.NumTimesFailed;
              level2.NumTimesPassed =
                level2?.NumTimesPassed + item?.NumTimesPassed;
            } else if (item?.LevelID == 3) {
              level3.TotalQuestionsAttempted =
                level3?.TotalQuestionsAttempted + item?.TotalQuestionsAttempted;
              level3.TotalNumQuestionsMissed =
                level3?.TotalNumQuestionsMissed + item?.TotalNumQuestionsMissed;
              level3.TotalNumQuestionsCorrect =
                level3?.TotalNumQuestionsCorrect +
                item?.TotalNumQuestionsCorrect;
              level3.NumTimesAttempted =
                level3?.NumTimesAttempted + item?.NumTimesAttempted;
              level3.NumTimesFailed =
                level3?.NumTimesFailed + item?.NumTimesFailed;
              level3.NumTimesPassed =
                level3?.NumTimesPassed + item?.NumTimesPassed;
            } else if (item?.LevelID == 4) {
              level4.TotalQuestionsAttempted =
                level4?.TotalQuestionsAttempted + item?.TotalQuestionsAttempted;
              level4.TotalNumQuestionsMissed =
                level4?.TotalNumQuestionsMissed + item?.TotalNumQuestionsMissed;
              level4.TotalNumQuestionsCorrect =
                level4?.TotalNumQuestionsCorrect +
                item?.TotalNumQuestionsCorrect;
              level4.NumTimesAttempted =
                level4?.NumTimesAttempted + item?.NumTimesAttempted;
              level4.NumTimesFailed =
                level4?.NumTimesFailed + item?.NumTimesFailed;
              level4.NumTimesPassed =
                level4?.NumTimesPassed + item?.NumTimesPassed;
            } else if (item?.LevelID == 5) {
              level5.TotalQuestionsAttempted =
                level5?.TotalQuestionsAttempted + item?.TotalQuestionsAttempted;
              level5.TotalNumQuestionsMissed =
                level5?.TotalNumQuestionsMissed + item?.TotalNumQuestionsMissed;
              level5.TotalNumQuestionsCorrect =
                level5?.TotalNumQuestionsCorrect +
                item?.TotalNumQuestionsCorrect;
              level5.NumTimesAttempted =
                level5?.NumTimesAttempted + item?.NumTimesAttempted;
              level5.NumTimesFailed =
                level5?.NumTimesFailed + item?.NumTimesFailed;
              level5.NumTimesPassed =
                level5?.NumTimesPassed + item?.NumTimesPassed;
            }
            count++;
            if (count === querySnapshot.size) {
              resolve([level1, level2, level3, level4, level5]);
            }
          });
          if (querySnapshot.size === 0) {
            resolve([]);
          }
        } else {
          reject(null);
        }
      });
  });
};

export const getTotalSectionData = () => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection(tableNames.section)
      .get()
      .then((querySnapshot) => {
        let section = [];
        let count = 0;
        if (querySnapshot !== null) {
          querySnapshot.forEach(async function (documentSnapshot) {
            const item = documentSnapshot.data();
            section.push({
              id: documentSnapshot.id,
              ...item,
            });
            count++;
            if (count === querySnapshot.size) {
              const sortedData = section.sort(function (a, b) {
                return a.SectionNumber - b.SectionNumber;
              });
              resolve(sortedData);
            }
          });
          if (querySnapshot.size === 0) {
            resolve([]);
          }
        } else {
          reject(null);
        }
      });
  });
};

export const getQuesDataByLevelAndCustomID = (lId, cId) => {
  //.orderBy("QNo", "asc")
  console.log("LID : ", lId);
  console.log("SID : ", cId);

  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection(tableNames.question)
      .orderBy("QNo", "asc")
      .onSnapshot((querySnapshot) => {
        let section = [];
        let count = 0;
        if (querySnapshot !== null) {
          querySnapshot.forEach(async function (documentSnapshot) {
            const item = documentSnapshot.data();
            if (cId === "" && item.LevelId === lId) {
              section.push({
                id: documentSnapshot.id,
                ...item,
              });
            } else {
              if (item.CustomLessonID === cId && item.LevelId === lId) {
                console.log("[[[[[first]]]]]");
                section.push({
                  id: documentSnapshot.id,
                  ...item,
                });
              }
            }
            count++;
            if (count === querySnapshot.size) {
              console.log("-----", section);
              resolve(section);
            }
          });
          if (querySnapshot.size === 0) {
            resolve([]);
          }
        } else {
          reject("error");
        }
      });
  });
};

export const getQuesDataByLevelAndSection = (lId, sId, cId, tId) => {
  const llId = isNaN(lId) ? undefined : lId;
  const ssId = sId;
  const ccId = isNaN(cId) ? undefined : cId;
  const ttId = isNaN(tId) ? undefined : tId;
  //.orderBy("QNo", "asc")
  console.log("LID : ", llId);
  console.log("SID : ", sId);
  console.log("CID : ", ccId);
  console.log("TID : ", ttId);

  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection(tableNames.question)
      .orderBy("QNo", "asc")
      .onSnapshot((querySnapshot) => {
        let section = [];
        let count = 0;
        if (querySnapshot !== null) {
          querySnapshot.forEach(async function (documentSnapshot) {
            const item = documentSnapshot.data();
            if (
              (ssId === "" || ssId === undefined) &&
              item?.LevelId === llId &&
              (ccId === "" || ccId === undefined) &&
              (ttId === "" || ttId === undefined)
            ) {
              section.push({
                id: documentSnapshot.id,
                ...item,
              });
            } else if (
              (ssId === "" || ssId === undefined) &&
              item?.LevelId === llId &&
              item?.CustomLessonID === ccId &&
              (ttId === "" || ttId === undefined)
            ) {
              section.push({
                id: documentSnapshot.id,
                ...item,
              });
            } else if (
              (ccId === "" || ccId === undefined) &&
              item?.LevelId === llId &&
              ssId === item?.SectionId &&
              (ttId === "" || ttId === undefined)
            ) {
              section.push({
                id: documentSnapshot.id,
                ...item,
              });
            } else if (
              item?.LevelId === llId &&
              (ssId === "" || ssId === undefined) &&
              (ccId === "" || ccId === undefined) &&
              ttId === item?.CustomTopicID
            ) {
              section.push({
                id: documentSnapshot.id,
                ...item,
              });
            } else if (
              item?.LevelId === llId &&
              ssId === item?.SectionId &&
              ccId === item?.CustomLessonID &&
              (ttId === "" || ttId === undefined)
            ) {
              section.push({
                id: documentSnapshot.id,
                ...item,
              });
            } else if (
              item?.LevelId === llId &&
              (ssId === "" || ssId === undefined) &&
              ccId === item?.CustomLessonID &&
              ttId === item?.CustomTopicID
            ) {
              section.push({
                id: documentSnapshot.id,
                ...item,
              });
            } else if (
              item?.LevelId === llId &&
              ssId === item?.SectionId &&
              (ccId === "" || cId === undefined) &&
              ttId === item?.CustomTopicID
            ) {
              section.push({
                id: documentSnapshot.id,
                ...item,
              });
            } else if (
              item?.LevelId === llId &&
              ssId === item?.SectionId &&
              ccId === item?.CustomLessonID &&
              ttId === item?.CustomTopicID
            ) {
              section.push({
                id: documentSnapshot.id,
                ...item,
              });
            } else {
              // if( !cId && sId && item?.SectionId === sId && item.LevelId === lId){
              //     section.push({
              //         id: documentSnapshot.id,
              //         ...item,
              //     });
              //     console.log("second")
              // } else if(cId && sId){
              //     if( item?.CustomLessonID === cId && item?.SectionId === sId && item.LevelId === lId){
              //         console.log(item?.CustomLessonID, item?.SectionId, sId, item.LevelId, lId)
              //         section.push({
              //             id: documentSnapshot.id,
              //             ...item,
              //         });
              //     }
              //     console.log(item)
              // } else if(cId && item?.CustomLessonID === cId && item.LevelId === lId){
              //     if( item.CustomLessonID === cId && item.LevelId === lId){
              //         section.push({
              //             id: documentSnapshot.id,
              //             ...item,
              //         });
              //     }
              //     console.log("third2")
              // }
            }

            count++;
            if (count === querySnapshot.size) {
              // const sortedData = section.sort(function(a, b) {
              //     return a.QNo - b.QNo;
              // });
              resolve(section);
            }
          });
          if (querySnapshot.size === 0) {
            resolve([]);
          }
        } else {
          reject("error");
        }
      });
  });
};

export const setQuestion = (data) => {
  return (dispatch) => {
    dispatch({
      type: t.QUESTION_DATA_LOAD,
      payload: data,
    });
  };
};

export const addNewItem = (item) => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection(tableNames.question)
      .add(item)
      .then((res) => {
        if (res) {
          resolve(true);
        } else {
          reject("error");
        }
      });
  });
};

export const addNewItemToSection = (item) => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection(tableNames.section)
      .add(item)
      .then((res) => {
        if (res) {
          resolve(true);
        } else {
          reject("error");
        }
      });
  });
};
export const updateItem = (id, item) => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection(tableNames.question)
      .doc(id)
      .update(item)
      .then((res) => {
        resolve(true);
      })
      .catch((err) => {
        reject(false);
      });
  });
};

export const deleteItem = (id) => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection(tableNames.question)
      .doc(id)
      .delete()
      .then((res) => {
        resolve(true);
      })
      .catch((err) => {
        reject(false);
      });
  });
};

export const getAllLessons = async () => {
  //fetch all lessons from firebase collecction CustomLessonMapping
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection(tableNames.customLessonMapping)
      .get()
      .then((querySnapshot) => {
        let lessons = [];
        let count = 0;
        if (querySnapshot !== null) {
          querySnapshot.forEach(async function (documentSnapshot) {
            const item = documentSnapshot.data();
            if (item.LevelId === 4 || item.LevelId === 5 || item.LevelId === 6) {
              lessons.push({
                id: documentSnapshot.id,
                ...item,
              });
            }


            resolve(lessons);

          });
          if (querySnapshot.size === 0) {
            resolve([]);
          }
        } else {
          reject(null);
        }
      });
  });
}

export const updateLessonLastUpdatedTime = async (lessonIdList) => {
  // use batch to update all lessons last updated time with firebase server timestamp
  console.log("LESSON ID LIST", lessonIdList);
  const batch = firebase.firestore().batch();
  try {
    lessonIdList.map(async (item) => {
      const docRef = firebase
        .firestore()
        .collection(tableNames.customLessonMapping)
        .doc(item.id);
      console.log("docRef added to batch");
      batch.update(docRef, { LastUpdate: firebase.firestore.FieldValue.serverTimestamp(), RawVideo: item.VideoURL });
    });
    console.log("batch commit started");
    await batch.commit()
    console.log("batch commit done");
  } catch (e) {
    console.log("ERROR WHILE UPDATING BULK LESSONS", e);

  }

}