import { combineReducers } from 'redux';

import AuthReducer from './AuthReducer';
import LevelReducer from './LevelReducer';
import ResultReducer from './ResultReducer';

export const rootReducer = combineReducers({
    AuthReducer,
    LevelReducer,
    ResultReducer,
});