import { View, Text, StyleSheet, ActivityIndicator } from 'react-native'
import React from 'react'
import { GradientCircularProgress } from 'react-native-circular-gradient-progress'
import { Units } from '../../constants/Unit'
import { EDColors } from '../../constants/Colors'
import { useState } from 'react'
import { useEffect } from 'react'
import { getAverageOfArrayWithParameter } from '../../utils/helper'

const ResultContainer = ({
  userExamData,
  isLoadingTabs
}) => {
  const [average, setAverage] = useState(0);
  const userDataLength = userExamData?.length

  const calculateAverage = async() => {
    try {
      const average = await getAverageOfArrayWithParameter(userExamData, 'Score');
      // console.log("=============", average)
      setAverage(Math.floor(average))
    } catch(e) {
      console.log("CALCULATE AVERAGE ERROR: ", e)
    }
  }

  useEffect(()=>{
    if(userExamData) {
      calculateAverage();
    }
  }, [userExamData])
  return (
    <View style={styles.container}>
      {isLoadingTabs ? (
        <ActivityIndicator size="large" color={EDColors.gradient[1]} />
      ) : !isLoadingTabs && userExamData ? (
        <>
          <View style={[styles.horizontalContainer, {marginBottom: 10}]}>
            <View>
              <Text style={styles.boldTxt}>Average score</Text>
              <Text style={styles.attemptTxt}>Last 3 attempts</Text>
            </View>
            <GradientCircularProgress
              size = {90}
              startColor = {EDColors.gradient[2]}
              middleColor = {EDColors.gradient[1]}
              endColor = {EDColors.gradient[0]}
              progress = {average ? average : 0}
              emptyColor = {EDColors.lightgrey}
              strokeWidth = {5}
              children = {<View style={styles.progressTxtWrapper}>
                  <Text style={styles.progressTxt}>{average ? average : 0}%</Text>
              </View>}
            />
          </View>
          {userExamData ? userExamData.map((item, index)=>{
            return (
              <>
                <View style={styles.horizontalContainer}>
                  <View>
                    <Text style={{
                      color: item?.Score < 74 ? "#BF1D27" : "#4AA0D3",
                      fontFamily: Units.ftFamily.MontserratR,
                      fontSize: 17
                    }}>{item?.Score < 74 ? "Fail" : "Pass"}</Text>
                    <Text style={styles.date}>{item?.dateTime}</Text>
                  </View>
                  <GradientCircularProgress
                    size = {54}
                    startColor = {EDColors.gradient[2]}
                    middleColor = {EDColors.gradient[1]}
                    endColor = {EDColors.gradient[0]}
                    progress = {item?.Score}
                    emptyColor = {EDColors.lightgrey}
                    strokeWidth = {4}
                    children = {<View style={{
                      ...StyleSheet.absoluteFillObject,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                        <Text style={{
                          fontFamily: Units.ftFamily.KarlaR,
                          color: EDColors.topbar,
                          textAlign: 'center',
                        }}>{item?.Score}%</Text>
                    </View>}
                  />
                </View>
                {userDataLength && userDataLength !== index + 1 ? (
                  <View style={{
                    width: "100%",
                    height: 1,
                    backgroundColor: "#ccc"
                  }} />
                ) : (null)}
              </>
            )
          }) : (
            <Text>Something Went Wrong</Text>
          )}
        </>
      ) : (null)}
    </View>
  )
}

export default ResultContainer

const styles = StyleSheet.create({
    container: {
        marginHorizontal: "auto",
        width: 330,
        marginTop: 50
    },
    horizontalContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginVertical: 20
    },
    boldTxt: {
        fontSize: 26,
        color: "#010A41",
        lineHeight: 39,
        fontFamily: Units.ftFamily.MontserratB
    },
    attemptTxt: {
        color: "#010A41",
        fontSize: 17,
        fontFamily: Units.ftFamily.MontserratR
    },
    progressTxtWrapper: {
        ...StyleSheet.absoluteFillObject,
        alignItems: 'center',
        justifyContent: 'center',
    },
    progressTxt: {
        fontFamily: Units.ftFamily.KarlaR,
        color: EDColors.topbar,
        textAlign: 'center',
    },
    date: {
        color: "#010A41",
        fontFamily: Units.ftFamily.KarlaR,
        fontSize: 13
    }
})