import { Pressable, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { Entypo } from '@expo/vector-icons'
import { Units } from '../../constants/Unit'
import { EDColors } from '../../constants/Colors'
import { GradientCircularProgress } from 'react-native-circular-gradient-progress'

const Quescomp = ({
  levelId,
  Qno,
  fill,
  total,
  pass,
  failed,
  NCVECLesson,
  NCVECTopic,
  SectionID,
  byCustom,
  byTopic,
  customLessonID
}) => {
  return (
    <View style={styles.container}>
        <View style={{
          flexDirection: "row",
          alignItems: "center"
        }}>
          <View>
            <Text style={{
              color: "#0243DD",
              fontFamily: Units.ftFamily.MontserratR
            }}>{NCVECTopic ? "NCVEC Section" : NCVECLesson ? "NCVEC Lesson" : NCVECTopic ? "NCVEC Section" : byCustom ? "LevelID" : "QNO"}</Text>
            <Text style={{
              color: "#0243DD",
              fontFamily: Units.ftFamily.MontserratR
            }}>{byTopic ? "TopicID" : byCustom ? "LessonID" : "LevelID"}</Text>
          </View>
          <View style={{
            marginLeft: 20
          }}>
            <Text style={{
              color: "#0243DD",
              fontFamily: Units.ftFamily.MontserratR
            }}>:{byCustom ? levelId : NCVECLesson ? SectionID : NCVECTopic ? Qno.slice(0, 3) : Qno}</Text>
            <Text style={{
              color: "#0243DD",
              fontFamily: Units.ftFamily.MontserratR
            }}>:{byCustom ? customLessonID : levelId}</Text>
          </View>
        </View>
        <View style={{
          height: "100%",
          width: 1,
          backgroundColor: "#eee"
        }} />
        <View style={{
          flexDirection: "row",
          alignItems: "center"
        }}>
          <View style={{
            marginRight: 20
          }}>
            <Text style={{
              fontFamily: Units.ftFamily.MontserratR
            }}>Percent</Text>
            <Text style={{
              fontFamily: Units.ftFamily.MontserratR
            }}>Correct: </Text>
          </View>
          <GradientCircularProgress
              size={54}
              startColor={EDColors.gradient[2]}
              middleColor={EDColors.gradient[1]}
              endColor={EDColors.gradient[0]}
              progress={fill}
              emptyColor={EDColors.lightgrey}
              strokeWidth={4}
              children={<View style={{
                ...StyleSheet.absoluteFillObject,
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                  <Text style={{
                    fontFamily: Units.ftFamily.KarlaR,
                    color: EDColors.topbar,
                    textAlign: 'center',
                  }}>{fill}%</Text>
              </View>}
            />
        </View>
        <View style={{
          height: "100%",
          width: 1,
          backgroundColor: "#eee"
        }} />
        <View style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between"
        }}>
          <View style={{
            alignItems: "center",
            marginHorizontal: 10
          }}>
            <Text style={{
              fontFamily: Units.ftFamily.MontserratR
            }}>Number of Attempts:</Text>
            <Text style={{
              color: "#0243DD",
              fontFamily: Units.ftFamily.MontserratR
            }}>{total}</Text>
          </View>
          <View style={{
            alignItems: "center",
            marginHorizontal: 10
          }}>
            <Text style={{
              fontFamily: Units.ftFamily.MontserratR
            }}>Times Correct:</Text>
            <Text style={{
              color: "#15B86B",
              fontFamily: Units.ftFamily.MontserratR
            }}>{pass}</Text>
          </View>
          <View style={{
            alignItems: "center",
            marginHorizontal: 10
          }}>
            <Text style={{
              fontFamily: Units.ftFamily.MontserratR
            }}>Times Missed:</Text>
            <Text style={{
              color: "#BF1D27",
              fontFamily: Units.ftFamily.MontserratR
            }}>{failed}</Text>
          </View>
        </View>
        <Pressable style={{
          flexDirection: 'row',
          alignItems: "center",
          justifyContent: "center",
          height: "100%"
        }}>
          <View style={{
            height: "100%",
            width: 1,
            backgroundColor: "#eee",
            marginRight: 17
          }} />
          <Entypo name="chevron-down" size={24} color="black" />
        </Pressable>
      </View>
  )
}

export default Quescomp

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: "#eee",
    borderStyle: "solid",
    marginHorizontal: 17,
    marginTop: 10,
    paddingVertical:10,
    paddingHorizontal: 17,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.20,
    shadowRadius: 1.41,

    elevation: 2,
    borderRadius: 12
  }
})