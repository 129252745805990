import { Pressable, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { Units } from '../../constants/Unit'
import { enrollmentHandler, updateEnrollment } from '../../actions/OfflineAction'
import { auth } from 'firebase'
import { toast } from 'react-toastify'

const EnrollmentComponent = ({
    title,
    enrolled,
    enrollUser,
    productId,
    removeEnrollment,
    setIsLoadingEnrollment,
    userData,
    setModalEnrollOpen,
    setSetEnrolled,
    setProductId
}) => {
    

    return (
        <View style={styles.container}>
            <Text style={{
                color: '#010A41',
                width: 170,
                fontSize: 18,
                fontFamily: Units.ftFamily.MontserratR
            }}>{title}  :  </Text>
            <Text style={{
                width: 190,
                paddingVertical: 6,
                backgroundColor: enrolled ? "#00D395" : "#BF1D27",
                borderRadius: 10,
                color: "#fff",
                textAlign: "center",
                fontFamily: Units.ftFamily.MontserratB,
                fontSize: 16
            }}>{enrolled ? 'Enrolled' : "Not Enrolled"}</Text>
            <Pressable onPress={()=>{
                setModalEnrollOpen(true);
                setProductId(productId);
                setSetEnrolled(enrolled);
            }}>
                <Text style={{
                    color: enrolled ? "#EE0A0A" : "#03CA3B",
                    marginLeft: 10,
                    textDecorationLine: "underline",
                    textDecorationColor: enrolled ? "red" : "#03CA3B",
                    fontFamily: Units.ftFamily.MontserratB,
                    fontSize: 13
                }}>{enrolled ? 'Remove Enrollment' : 'Enroll User'}</Text>
            </Pressable>
        </View>
    )
}

export default EnrollmentComponent

const styles = StyleSheet.create({
    container: {
        marginVertical: 8,
        flexDirection: "row",
        alignItems: "center"
    }
})