import React from 'react';
import {
    View,
    Image,
    Text,
    StyleSheet,
    TouchableOpacity,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { EDColors } from '../constants/Colors';
import { Units } from '../constants/Unit';
import { Assets } from '../constants/Images';

const TopBar =({title, setting, handleSetting})=> {
    return (
        <View style={styles.headerCon}>
            <View style={styles.leftCon}>
                <Image source = {title? Assets.wave: Assets.titlelogo} style={title? styles.waveImg: styles.waveImg2} resizeMode = "contain"/>
                {title&&<Text style={styles.title}>{title.toUpperCase()}</Text>}
            </View>
            {setting && <TouchableOpacity style = {styles.logout} onPress={handleSetting}>
                <Ionicons
                    name="settings-outline"
                    color = {EDColors.primary}
                    size = {28}
                />
            </TouchableOpacity>}
        </View>
    );
}

const styles = StyleSheet.create({
    headerCon:{
        width: '100%',
        // height: Units.isTablet? 150 : 80,
        backgroundColor: EDColors.topbar,
        padding: Units.isTablet? 30: 10,
        paddingTop:Units.isTablet? Units.statusH + 20 : Units.statusH + 10,
        paddingBottom: Units.isTablet? 15: 5,
        flexDirection: 'row',
        alignItems:'center', 
        justifyContent: 'space-between'
    },
    leftCon: {
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
    },
    title:{
        fontFamily: Units.ftFamily.MontserratB,
        color: EDColors.white,
        fontSize: Units.ftSizes.title,
        letterSpacing: 2,
        marginLeft: Units.isTablet? 15: 10,
    },
    waveImg: {
        marginLeft: Units.isTablet? 20: 10,
        height: Units.isTablet? 80: 50,
        width: Units.isTablet? 79 : 55.5,
    },
    waveImg2: {
        marginLeft: Units.isTablet? 20: 10,
        height: Units.isTablet? 80: 50,
        width: Units.isTablet? 400 : 280,
    },
    logout: {
        paddingHorizontal: 10,
        paddingVertical: 5,
    }
})
export default TopBar;