import { StyleSheet, Text, TextInput, View, Pressable } from 'react-native'
import React, { useEffect } from 'react'
import { Units } from '../../constants/Unit'
import { Picker } from '@react-native-community/picker'
import { GradientButton3 } from '../../components'

const HeaderComp = ({
    questionLen,
    curIndex,
    _handleSelectItem,
    renderData,
    curSectionIdx,
    _handleSelectSectionItem,
    sectionData,
    qNo,
    _handleSearch,
    handleExportToCSV,
    crtCustomIndex,
    _handleCustomLessonId,
    crtTopicIndex,
    _handleCustomTopicId
}) => {

    return (
        <>
            <Text style={styles.mainTitle}>Question Pool Admin</Text>
            <View style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 20
            }}>
                <Text style={{
                    fontFamily: Units.ftFamily.MontserratR,
                    fontSize: 17,
                    color: "#010A41"
                }}>Total Question Count for Current Section: </Text>
                <Pressable style={{
                    backgroundColor: "#010A41",
                    height: 30,
                    width: 180,
                    borderRadius: 20,
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Text style={{
                        color: "#17F9DA",
                        fontFamily: Units.ftFamily.MontserratR,
                        fontSize: 17
                    }}>{questionLen} Questions</Text>
                </Pressable>
            </View>
            <View style={styles.filterWrapper}>
                <View style={styles.filterChild}>
                    <Text style={styles.pickerTxt}>Select Level</Text>
                    {renderData ? (
                        <Picker
                            selectedValue={curIndex}
                            onValueChange={(_h) => _handleSelectItem(_h)}
                            style={styles.picker}
                            mode="dropdown"
                        >
                            {/* <Picker.Item label={""} value={-1} key={"blank"} /> */}
                            {
                                renderData.map((item, index) => {
                                    return (
                                        <Picker.Item label={item.Name} value={index} key={index.toString()} />
                                    )
                                })
                            }
                        </Picker>
                    ) : (null)}
                </View>
                <View style={styles.filterChild}>
                    <Text style={styles.pickerTxt}>Select Section</Text>
                    <Picker
                        selectedValue={curSectionIdx}
                        onValueChange={(_h) => _handleSelectSectionItem(_h)}
                        style={styles.picker}
                        mode="dropdown"
                    >
                        <Picker.Item label={""} value={-1} key={"blank"} />
                        {
                            sectionData.map((item, index) => {
                                return (
                                    <Picker.Item label={item.Name} value={index} key={index.toString()} />
                                )
                            })
                        }
                    </Picker>
                </View>
                <View style={styles.filterChild}>
                    <Text style={styles.pickerTxt}>Select by Question Number:</Text>
                    <TextInput
                        placeholder={"Number Selection"}
                        value={qNo}
                        onChangeText={(txt) => _handleSearch(txt)}
                        style={styles.textInput}
                        placeholderStyle={styles.textInputPlaceholder}
                    />
                </View>
                <View style={styles.filterChild}>
                    <Text style={styles.pickerTxt}>Select Custom Lesson ID</Text>
                    <Picker
                        selectedValue={crtCustomIndex}
                        onValueChange={(_h) => _handleCustomLessonId(_h)}
                        style={styles.picker}
                        mode="dropdown"
                    >
                        <Picker.Item label={""} value={null} key={"blank"} />
                        {Array(200).fill(0).map((_, i) => {
                            return (
                                <Picker.Item label={i + 1} value={i + 1} key={i} />
                            )
                        })}
                    </Picker>
                </View>
                <View style={styles.filterChild}>
                    <Text style={styles.pickerTxt}>Select Custom Topic ID</Text>
                    <Picker
                        selectedValue={crtTopicIndex}
                        onValueChange={(_h) => _handleCustomTopicId(_h)}
                        style={styles.picker}
                        mode="dropdown"
                    >
                        <Picker.Item label={""} value={null} key={"blank"} />
                        {/* {
                            [68,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22].map((item, index) => {
                                return (
                                    <Picker.Item label={item} value={item} key={index} />
                                )
                            })
                        } */}
                        {Array(200).fill(0).map((_, i) => {
                            return (
                                <Picker.Item label={i + 1} value={i + 1} key={i} />
                            )
                        })}
                    </Picker>
                </View>
                <GradientButton3
                    text="EXPORT TO CSV"
                    handlePress={() => handleExportToCSV()}
                    width={220}
                    style={styles.gradientButton}
                />
            </View>
        </>
    )
}

export default HeaderComp

const styles = StyleSheet.create({
    mainTitle: {
        color: "#010A41",
        fontFamily: Units.ftFamily.MontserratB,
        fontSize: 36,
        marginTop: 50,
        textAlign: "center"
    },
    filterWrapper: {
        width: "100%",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "flex-end",
        marginVertical: 30,
        paddingHorizontal: 30
    },
    filterChild: {
        justifyContent: "flex-end",
        marginBottom: 30,
    },
    pickerTxt: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: 16,
        color: "#010A41"
    },
    picker: {
        borderRadius: 20,
        height: 40,
        marginTop: 20,
        width: 220
    },
    textInput: {
        backgroundColor: "#fff",
        marginTop: 20,
        height: 40,
        borderRadius: 20,
        paddingLeft: 10,
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: 14,
        borderWidth: 1,
        borderColor: "#777",
        borderStyle: "solid"
    },
    textInputPlaceholder: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: 14
    },
    gradientButton: {
        alignSelf: "flex-end",
        marginBottom: 30
    }
})