import React, { useState } from 'react';
import { useEffect } from 'react';
import { Text, StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

import { Dropdown, GradientButton3, InputWithName } from '../../components';
import { EDColors } from '../../constants/Colors';
import { Units } from '../../constants/Unit';

const IsImageData = [
    {
        Name: "Y",
    }, {
        Name: "N"
    }];
const ImageData = [{
    Name: "T1"
}, {
    Name: "T2"
}, {
    Name: "T3"
},
{
    Name: "E501"
},
{
    Name: "E601"
}, {
    Name: "E602"
}, {
    Name: "E603"
}, {
    Name: "E701"
}, {
    Name: "E702"
}, {
    Name: "E703"
}, {
    Name: "E901"
}, {
    Name: "E902"
}, {
    Name: "E903"
}, {
    Name: "General_Diagram"
},
];

const crtData = [{
    Name: "A"
}, {
    Name: "B"
}, {
    Name: "C"
}, {
    Name: "D"
}]

const QuesBlock = ({ item, quesData, lData, sData, isMobile, isNew, onClose, sHeight, handleAddNewItem, deleteItem, updateItem }) => {
    const [curLIdx, setCurLIdx] = useState(item.LevelId === -1 ? -1 : item.LevelId - 4);
    const [curSIdx, setCurSIdx] = useState(-1);
    const [curIsImageIdx, setCurIsImageIdx] = useState(-1);
    const [curImageIdx, setCurImageIdx] = useState(-1);
    const [curCrtIdx, setCurCrtIdx] = useState(-1);

    const [qNo, setQuesNo] = useState(item.QNo);
    const [question, setQuestion] = useState(item.Question);
    const [customLessonID, setCustomLessonID] = useState(item?.CustomLessonID ? item?.CustomLessonID : null);
    const [customTopicID, setCustomTopicID] = useState(item?.CustomTopicID ? item?.CustomTopicID : null);
    const [ansA, setAnsA] = useState(item.AnswerA);
    const [ansB, setAnsB] = useState(item.AnswerB);
    const [ansC, setAnsC] = useState(item.AnswerC);
    const [ansD, setAnsD] = useState(item.AnswerD);
    const [help, setHelp] = useState(item.Help);

    const [qNoExist, setQNoExist] = useState(false);
    const [bImageName, setBImageName] = useState(false);

    useEffect(() => {

        if (item.SectionId !== "") {
            sData[curLIdx].map((_, _i) => {
                if (_i < 10) {
                    if (_.SectionId === item.SectionId) {
                        setCurSIdx(_i);
                    }
                }
            });
            IsImageData.map((_, _i) => {
                if (_.Name === item.IsImageQuestion) {
                    setCurIsImageIdx(_i);
                }
            });
            ImageData.map((_, _i) => {
                if (item.imageName !== undefined) {
                    if (_.Name === item.imageName.split('.')[0]) {
                        setCurImageIdx(_i);
                    }
                }
            });
            crtData.map((_, _i) => {
                if (_.Name === item.Correctanswer || _.Name === item.CorrectAnswer) {
                    setCurCrtIdx(_i);
                }
            });
        }
    }, []);

    const handleAddOrUpdate = () => {
        if (!validateAllItems()) {
            return;
        }
        const lId = parseInt(curLIdx);
        const sId = parseInt(curSIdx);
        const newItem = {
            AnswerA: ansA,
            AnswerB: ansB,
            AnswerC: ansC,
            AnswerD: ansD,
            CustomLessonID: customLessonID,
            CustomTopicID: customTopicID,
            Correctanswer: crtData[parseInt(curCrtIdx)].Name,
            Help: help,
            IsImageQuestion: curIsImageIdx === -1 ? "N" : IsImageData[parseInt(curIsImageIdx)].Name,
            LevelId: lId === -1 ? 1 : parseInt(lId) + 4,
            QNo: qNo,
            Question: question,
            Section: sData[lId === -1 ? 0 : lId][parseInt(sId)].SectionId,
            SectionId: sData[lId === -1 ? 0 : lId][parseInt(sId)].SectionId,
            imageName: parseInt(curIsImageIdx) === 0 && parseInt(curImageIdx) !== -1 ? ImageData[parseInt(curImageIdx)].Name + ".png" : "",
        }
        isNew ? handleAddNewItem(newItem) : updateItem(item.id, newItem);
    }


    const validateAllItems = () => {
        if (curLIdx === -1) {
            return false;
        }
        if (qNo === "") { return false; }
        if (question === "") { return false; }
        if (ansA === "") { return false; }
        if (ansB === "") { return false; }
        if (ansC === "") { return false; }
        if (ansD === "") { return false; }
        if (curCrtIdx === -1) { return false; }
        // if( help === "" ){ return false; }
        if (curSIdx === -1) { return false; }
        if (curIsImageIdx === -1) { return false; }
        if (curIsImageIdx === 0 && curImageIdx === -1) {
            setBImageName(true);
            return false;
        }

        if (isNew) {
            const temp = quesData.filter((_) => _.QNo === qNo);
            if (temp && temp.length > 0) {
                setQNoExist(true);
                return false;
            }
        }

        return true;
    }

    return (
        <View style={isNew ? [styles.container, { maxHeight: sHeight - 100 }] : [styles.container, { maxHeight: sHeight }]}>
            <ScrollView style={{ flex: 1, maxHeight: isNew ? sHeight - 100 : sHeight }} showsVerticalScrollIndicator={false}>
                <Dropdown
                    isMobile={isMobile}
                    data={lData}
                    blank
                    handlePress={(_h) => {
                        setCurLIdx(parseInt(_h))
                        console.log("SDATA : ", sData)
                        console.log("_H : ", _h)
                    }}
                    text="Level: "
                    curIndex={curLIdx}
                />
                <InputWithName
                    isMobile={isMobile}
                    label={"Question Number"}
                    placeholder={"Input question number..."}
                    value={qNo}
                    onChangeText={(txt) => {
                        setQuesNo(txt);
                        if (qNoExist) {
                            setQNoExist(false);
                        }
                    }}
                />
                {qNoExist && isNew && <View style={styles.validateCon}>
                    {!isMobile && <View style={{ minWidth: 140 }}></View>}
                    <Text style={styles.valTxt}>This number already exist!</Text>
                </View>}
                <InputWithName
                    isMobile={isMobile}
                    label={"Question"}
                    placeholder={"Input question..."}
                    value={question}
                    onChangeText={(txt) => setQuestion(txt)}
                />
                <InputWithName
                    isMobile={isMobile}
                    label={"Lesson ID"}
                    placeholder={"Input lesson id..."}
                    value={customLessonID}
                    onChangeText={(txt) => setCustomLessonID(txt)}
                />
                <InputWithName
                    isMobile={isMobile}
                    label={"Topic ID"}
                    placeholder={"Input topic id..."}
                    value={customTopicID}
                    onChangeText={(txt) => setCustomTopicID(txt)}
                />
                <InputWithName
                    isMobile={isMobile}
                    label={"Answer A"}
                    placeholder={"Input answer..."}
                    value={ansA}
                    onChangeText={(txt) => setAnsA(txt)}
                />
                <InputWithName
                    isMobile={isMobile}
                    label={"Answer B"}
                    placeholder={"Input answer..."}
                    value={ansB}
                    onChangeText={(txt) => setAnsB(txt)}
                />
                <InputWithName
                    isMobile={isMobile}
                    label={"Answer C"}
                    placeholder={"Input answer..."}
                    value={ansC}
                    onChangeText={(txt) => setAnsC(txt)}
                />
                <InputWithName
                    isMobile={isMobile}
                    label={"Answer D"}
                    placeholder={"Input answer..."}
                    value={ansD}
                    onChangeText={(txt) => setAnsD(txt)}
                />
                <Dropdown
                    isMobile={isMobile}
                    data={crtData}
                    blank
                    handlePress={(_h) => setCurCrtIdx(parseInt(_h))}
                    text="Correct Answer: "
                    curIndex={curCrtIdx}
                />
                <InputWithName
                    isMobile={isMobile}
                    label={"Explanation"}
                    placeholder={"Input explanation..."}
                    value={help}
                    onChangeText={(txt) => setHelp(txt)}
                />
                <Dropdown
                    isMobile={isMobile}
                    data={curLIdx === -1 ? sData[0] : sData[curLIdx]}
                    blank
                    section
                    handlePress={(_h) => {

                        setCurSIdx(parseInt(_h))
                    }}
                    text="Section: "
                    curIndex={curSIdx}
                />
                <Dropdown
                    isMobile={isMobile}
                    data={IsImageData}
                    blank
                    handlePress={(_h) => setCurIsImageIdx(parseInt(_h))}
                    text="IsImageQuestion: "
                    curIndex={curIsImageIdx}
                />
                <Dropdown
                    isMobile={isMobile}
                    data={ImageData}
                    blank
                    handlePress={(_h) => {
                        setCurImageIdx(parseInt(_h));
                        if (bImageName) {
                            setBImageName(false);
                        }
                    }}
                    text="ImageName: "
                    curIndex={curImageIdx}
                />
                {bImageName && <View style={styles.validateCon}>
                    {!isMobile && <View style={{ minWidth: 140 }}></View>}
                    <Text style={styles.valTxt}>This field required!</Text>
                </View>}
                <View style={[styles.actionCon, { flexDirection: isMobile ? "column" : "row" }]}>
                    <View style={{ width: isMobile ? "100%" : "50%", zIndex: 9 }} key="1">
                        <GradientButton3
                            text={isNew ? "Add" : "update"}
                            handlePress={() => handleAddOrUpdate()}
                        />
                    </View>
                    {isMobile && <View style={{ height: 20, }}></View>}
                    <View style={{ width: isMobile ? "100%" : "50%", zIndex: 9 }} key="2">
                        <GradientButton3
                            text={isNew ? "cancel" : "delete"}
                            handlePress={() => {
                                setQNoExist(false);
                                isNew ? onClose() : deleteItem(item.id);
                            }}
                        />
                    </View>
                </View>
            </ScrollView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        width: "100%",
        marginVertical: 10,
        paddingTop: 20,
        padding: 10,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: EDColors.gradient[2],
        shadowColor: EDColors.white,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        backgroundColor: EDColors.white,
    },
    actionCon: {
        flex: 1,
        paddingVertical: 20,
    },
    valTxt: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: Units.ftSizes.small,
        color: EDColors.warning
    },
    validateCon: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: -5,
        marginBottom: 10,
    }
});

export default QuesBlock;
