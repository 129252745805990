import { Pressable, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { Units } from '../../constants/Unit'

const ResultTab = ({
    setResultTab,
    resultTab,
    setIsLoadingTabs
}) => {
  return (
    <View style={styles.container}>
        <Pressable style={styles.button} onPress={resultTab !== "Technician" ? ()=> {
          setResultTab("Technician");
          setIsLoadingTabs(true);
        } : () => {}}>
          <Text style={{color: resultTab !== "Technician" ? "#010A41" : "#4AA0D3", fontFamily: Units.ftFamily.MontserratR, fontSize: 16}}>Technician</Text>
        </Pressable>
        <View style={styles.divider} />
        <Pressable style={styles.button} onPress={resultTab !== "General" ? ()=> {
          setResultTab("General");
          setIsLoadingTabs(true);
        } : () => {}}>
          <Text style={{color: resultTab !== "General" ? "#010A41" : "#4AA0D3", fontFamily: Units.ftFamily.MontserratR, fontSize: 16}}>General</Text>
        </Pressable>
        <View style={styles.divider} />                          
        <Pressable style={styles.button} onPress={resultTab !== "Amateur Extra" ? ()=> {
          setResultTab("Amateur Extra");
          setIsLoadingTabs(true);
        } : () => {}}>
          <Text style={{color: resultTab !== "Amateur Extra" ? "#010A41" : "#4AA0D3", fontFamily: Units.ftFamily.MontserratB, fontSize: 16}}>Amateur Extra</Text>
        </Pressable>
      </View>
  )
}

export default ResultTab

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        justifyContent: "space-evenly"
    },
    button: {
        paddingVertical: 8,
        paddingHorizontal: 20
    },
    divider: {
        width: 1,
        height: 20,
        backgroundColor: "#ccc"
    }
})